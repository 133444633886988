/* TODO Fonts should be split into a separate file */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+SC:wght@100;300;400;500;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root{
    --roboto: "Roboto", 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

*{
    font-family: var(--roboto);
}
@font-face {
    font-family: "ventiv-icons";
    src: url("/Alpha/static/custom/common/fonts/ventiv-icons/ventiv-icons.ttf") format("truetype"),
    url("/Alpha/static/custom/common/fonts/ventiv-icons/ventiv-icons.woff") format("woff"),
    url("/Alpha/static/custom/common/fonts/ventiv-icons/ventiv-icons.svg#icons") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Noto Sans SC";
    src: url("/Alpha/static/custom/common/fonts/Noto-Sans-SC/NotoSansSC-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Noto Sans JP";
    src: url("/Alpha/static/custom/common/fonts/Noto-sans-JP/NotoSansJP-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

:lang(ja){
    font-family: "Noto Sans JP",Sans-serif !important;
}
:lang(zh) {
    font-family: 'Noto Sans SC', sans-serif !important;
}

.ventiv-icon {
    font-family: "ventiv-icons" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-ic_expand_out {
    position: relative;
    top: 4px;
    left: 5px;
}

.ventiv-icon.globe:before {
    content: "\e600";
}
.ventiv-icon.calendar:before {
    content: "\e601";
}
.ventiv-icon.remove:before {
    content: "\e602";
}
.ventiv-icon.remove-x:before {
    /* This duplication of .close is needed because there are too many .close styles in LOD to override,
       but the .close icon is already in use elsewhere */
    content: "\e603";
}
.ventiv-icon.close:before {
    content: "\e603";
}
.ventiv-icon.menu:before {
    content: "\e604";
}
.ventiv-icon.play3:before {
    content: "\e605";
}
.ventiv-icon.file-text2:before {
    content: "\e606";
}
.ventiv-icon.icon-ic_action_off:before {
    content: "\e800";
}
.ventiv-icon.icon-ic_button:before {
    content: "\e801";
}
.ventiv-icon.icon-ic_cancel_save_on:before {
    content: "\e802";
}
.ventiv-icon.icon-ic_copyform_on:before {
    content: "\e803";
}
.ventiv-icon.icon-ic_create_on:before {
    content: "\e804";
}
.ventiv-icon.icon-ic_delete_on:before {
    content: "\e805";
}
.ventiv-icon.icon-ic_edit_on:before {
    content: "\e806";
}
.ventiv-icon.icon-ic_generate_on:before {
    content: "\e807";
}
.ventiv-icon.icon-ic_hand:before {
    content: "\e808";
}
.ventiv-icon.icon-ic_help:before {
    content: "\e809";
}
.ventiv-icon.icon-ic_info:before {
    content: "\e80a";
}
.ventiv-icon.icon-ic_layout:before {
    content: "\e80b";
}
.ventiv-icon.icon-ic_preview_off:before {
    content: "\e80c";
}
.ventiv-icon.icon-ic_reset_off:before {
    content: "\e80d";
}
.ventiv-icon.icon-ic_save_off_sm:before {
    content: "\e80e";
}
.ventiv-icon.icon-ic_template:before {
    content: "\e80f";
}
.ventiv-icon.icon-ic_udated_on:before {
    content: "\e810";
}
.ventiv-icon.icon-add_on:before {
    content: "\e811";
}
.ventiv-icon.icon-ic_delete:before {
    content: "\e812";
}
.ventiv-icon.icon-ic_help2:before {
    content: "\e813";
}
.ventiv-icon.icon-ic_save_as:before {
    content: "\e814";
}
.ventiv-icon.i-beam:before {
    content: "\e815";
}
.ventiv-icon.ventiv-icon-search:before {
    content: "\e816";
}
.ventiv-icon.icon-checkmark:before {
    content: "\e900";
}
.ventiv-icon.icon-ic_save:before {
    content: "\e901";
}
.ventiv-icon.icon-ic_save_as2:before {
    content: "\e902";
}
.ventiv-icon.icon-ic_reset:before {
    content: "\e903";
}
.ventiv-icon.icon-ic_preview:before {
    content: "\e904";
}
.ventiv-icon.icon-ic_preview2:before {
    content: "\e905";
}
.ventiv-icon.icon-ic_generate_page:before {
    content: "\e906";
}
.ventiv-icon.icon-ic_delete2:before {
    content: "\e907";
}
.ventiv-icon.icon-ic_arrow:before {
    content: "\e908";
}
.ventiv-icon.icon-ic_setting:before {
    content: "\e909";
}
.ventiv-icon.icon-ic_export:before {
    content: "\e90a";
}
.ventiv-icon.icon-ic_import:before {
    content: "\e90b";
}
.ventiv-icon.icon-ic_check_error:before {
    content: "\e90c";
}
.ventiv-icon.icon-ic_check:before {
    content: "\e90d";
}
.ventiv-icon.icon-ic_assoc:before {
    content: "\e90e";
}
.ventiv-icon.icon-ic_bo:before {
    content: "\e90f";
}
.ventiv-icon.icon-ic_field_gp:before {
    content: "\e911";
}
.ventiv-icon.icon-ic_grid_field:before {
    content: "\e912";
}
.ventiv-icon.icon-ic_grid:before {
    content: "\e913";
}
.ventiv-icon.icon-ic_query_builder:before {
    content: "\e914";
}
.ventiv-icon.icon-ic_subgroup:before {
    content: "\e915";
}
.ventiv-icon.icon-ic_reset2:before {
    content: "\e917";
}
.ventiv-icon.icon-ic_tools:before {
    content: "\e918";
}
.ventiv-icon.icon-ic_enter_info:before {
    content: "\e916";
}
.ventiv-icon.icon-ic_dyn_grid:before {
    content: "\e91c";
}
.ventiv-icon.icon-ic_financial_grid:before {
    content: "\e91d";
}
.ventiv-icon.icon-ic_home:before {
    content: "\e91e";
}
.ventiv-icon.icon-ic_lookup:before {
    content: "\e920";
}
.ventiv-icon.icon-ic_edit:before {
    content: "\e91f";
}
.ventiv-icon.icon-ic_expand_in:before {
    content: "\e921";
}
.ventiv-icon.icon-ic_expand_out:before {
    content: "\e922";
}
.ventiv-icon.icon-ic_gear:before {
    content: "\e923";
}
.ventiv-icon.icon-ic_down_arrow:before {
    content: "\e924";
}
.ventiv-icon.icon-ic_up_arrow:before {
    content: "\e925";
}
.ventiv-icon.icon-ic_refresh:before {
    content: "\e926";
}
.ventiv-icon.icon-ic_seq:before {
    content: "\e927";
}
.ventiv-icon.icon-ic_attachment:before {
    content: "\e93c";
}
.ventiv-icon.icon-ic_boolean:before {
    content: "\e93d";
}
.ventiv-icon.icon-ic_catastrophe:before {
    content: "\e93e";
}
.ventiv-icon.icon-ic_currency:before {
    content: "\e93f";
}
.ventiv-icon.icon-ic_currencytype:before {
    content: "\e940";
}
.ventiv-icon.icon-ic_currencytype:before {
    content: "\e940";
}
.ventiv-icon.icon-ic_instructionField:before {
    content: "\e953";
}
.ventiv-icon.icon-ic_largeTextField:before {
    content: "\e954";
}
.ventiv-icon.icon-ic_location:before {
    content: "\e955";
}
.ventiv-icon.icon-ic_lookupLibrary:before {
    content: "\e956";
}
.ventiv-icon.icon-ic_mtoField:before {
    content: "\e957";
}
.ventiv-icon.icon-ic_number:before {
    content: "\e958";
}
.ventiv-icon.icon-ic_string:before {
    content: "\e92d";
}
.ventiv-icon.icon-ic_summarizedCurrency:before {
    content: "\e95a";
}
.ventiv-icon.icon-ic_summarizedNumber:before {
    content: "\e95b";
}
.ventiv-icon.icon-ic_unknown:before {
    content: "\e95c";
}
.ventiv-icon.icon-ic_buisness_objects:before {
    content: "\e928";
}
.ventiv-icon.icon-ic_business_intelligence:before {
    content: "\e929";
}
.ventiv-icon.icon-ic_claim_doc:before {
    content: "\e936";
}
.ventiv-icon.icon-ic_dashboard:before {
    content: "\e937";
}
.ventiv-icon.icon-ic_data_exchange:before {
    content: "\e938";
}
.ventiv-icon.icon-ic_data_model_wb:before {
    content: "\e939";
}
.ventiv-icon.icon-ic_workbeach:before {
    content: "\e93a";
}
.ventiv-icon.icon-ic_workbench_home:before {
    content: "\e93b";
}
.ventiv-icon.icon-ic_date:before {
    content: "\e92a";
}
.ventiv-icon.icon-ic_business_rules:before {
    content: "\e92b";
}
.ventiv-icon.icon-ic_hyperlink:before {
    content: "\e952";
}
.ventiv-icon.icon-ic_stack_doc:before {
    content: "\e92c";
}

.ventiv-icon.icon-ic_attachment:before {
    content: "\e93c";
}
.ventiv-icon.icon-ic_boolean:before {
    content: "\e93d";
}
.ventiv-icon.icon-ic_catastrophe:before {
    content: "\e93e";
}
.ventiv-icon.icon-ic_currency:before {
    content: "\e93f";
}
.ventiv-icon.icon-ic_currencytype:before {
    content: "\e940";
}
.ventiv-icon.icon-ic_currencytype:before {
    content: "\e940";
}
.ventiv-icon.icon-ic_instructionField:before {
    content: "\e953";
}
.ventiv-icon.icon-ic_largeTextField:before {
    content: "\e954";
}
.ventiv-icon.icon-ic_location:before {
    content: "\e955";
}
.ventiv-icon.icon-ic_lookupLibrary:before {
    content: "\e956";
}
.ventiv-icon.icon-ic_mtoField:before {
    content: "\e957";
}
.ventiv-icon.icon-ic_number:before {
    content: "\e958";
}
.ventiv-icon.icon-ic_string:before {
    content: "\e92d";
}
.ventiv-icon.icon-ic_summarizedCurrency:before {
    content: "\e95a";
}
.ventiv-icon.icon-ic_summarizedNumber:before {
    content: "\e95b";
}
.ventiv-icon.icon-ic_unknown:before {
    content: "\e95c";
}
.ventiv-icon.icon-ic_buisness_objects:before {
    content: "\e928";
}
.ventiv-icon.icon-ic_business_intelligence:before {
    content: "\e929";
}
.ventiv-icon.icon-ic_claim_doc:before {
    content: "\e936";
}
.ventiv-icon.icon-ic_dashboard:before {
    content: "\e937";
}
.ventiv-icon.icon-ic_data_exchange:before {
    content: "\e938";
}
.ventiv-icon.icon-ic_data_model_wb:before {
    content: "\e939";
}
.ventiv-icon.icon-ic_workbeach:before {
    content: "\e93a";
}
.ventiv-icon.icon-ic_workbench_home:before {
    content: "\e93b";
}
.ventiv-icon.icon-ic_date:before {
    content: "\e92a";
}
.ventiv-icon.icon-ic_business_rules:before {
    content: "\e92b";
}


.ventiv-icon.icon-ic_attachment:before {
    content: "\e93c";
}
.ventiv-icon.icon-ic_boolean:before {
    content: "\e93d";
}
.ventiv-icon.icon-ic_catastrophe:before {
    content: "\e93e";
}
.ventiv-icon.icon-ic_currency:before {
    content: "\e93f";
}
.ventiv-icon.icon-ic_currencytype:before {
    content: "\e940";
}
.ventiv-icon.icon-ic_currencytype:before {
    content: "\e940";
}
.ventiv-icon.icon-ic_instructionField:before {
    content: "\e953";
}
.ventiv-icon.icon-ic_largeTextField:before {
    content: "\e954";
}
.ventiv-icon.icon-ic_location:before {
    content: "\e955";
}
.ventiv-icon.icon-ic_lookupLibrary:before {
    content: "\e956";
}
.ventiv-icon.icon-ic_mtoField:before {
    content: "\e957";
}
.ventiv-icon.icon-ic_number:before {
    content: "\e958";
}
.ventiv-icon.icon-ic_summarizedCurrency:before {
    content: "\e95a";
}
.ventiv-icon.icon-ic_summarizedNumber:before {
    content: "\e95b";
}
.ventiv-icon.icon-ic_unknown:before {
    content: "\e95c";
}
.ventiv-icon.icon-ic_buisness_objects:before {
    content: "\e928";
}
.ventiv-icon.icon-ic_business_intelligence:before {
    content: "\e929";
}
.ventiv-icon.icon-ic_claim_doc:before {
    content: "\e936";
}
.ventiv-icon.icon-ic_dashboard:before {
    content: "\e937";
}
.ventiv-icon.icon-ic_data_exchange:before {
    content: "\e938";
}
.ventiv-icon.icon-ic_data_model_wb:before {
    content: "\e939";
}
.ventiv-icon.icon-ic_workbeach:before {
    content: "\e93a";
}
.ventiv-icon.icon-ic_workbench_home:before {
    content: "\e93b";
}
.ventiv-icon.icon-ic_date:before {
    content: "\e92a";
}
.ventiv-icon.icon-ic_business_rules:before {
    content: "\e92b";
}
.ventiv-icon.icon-ic_drag_drop:before {
    content: "\e92e";
}
.ventiv-icon.icon-ic_newquery:before {
    content: "\e92f";
}
.ventiv-icon.icon-ic_preview3:before {
    content: "\e930";
}
.ventiv-icon.icon-ic_run_grid:before {
    content: "\e931";
}
.ventiv-icon.icon-ic_savedsearch:before {
    content: "\e932";
}
.ventiv-icon.icon-ic_release:before {
    content: "\e933";
}
.ventiv-icon.icon-ic_rulegrid:before {
    content: "\e934";
}
.ventiv-icon.icon-ic_broken_link:before {
    content: "\e935";
}
.ventiv-icon.icon-ic_addperson:before {
    content: "\e941";
}
.ventiv-icon.icon-ic_user_tab:before {
    content: "\e942";
}
.ventiv-icon.icon-ic_group:before {
    content: "\e943";
}
.ventiv-icon.icon-ic_single:before {
    content: "\e944";
}
.ventiv-icon.icon-ic_error:before {
    content: "\e945";
}
.ventiv-icon.icon-ic_expand_in:before {
    content: "\e921";
}
.ventiv-icon.icon-ic_expand_out:before {
    content: "\e922";
}
.ventiv-icon.icon-ic_gear:before {
    content: "\e923";
}
.ventiv-icon.icon-ic_refresh:before {
    content: "\e926";
}
.ventiv-icon.icon-ic_group_mgmt:before {
    content: "\e946";
}
.ventiv-icon.icon-ic_group_top_mgmt:before {
    content: "\e947";
}
.ventiv-icon.icon-ic_group_2:before {
    content: "\e948";
}
.ventiv-icon.icon-ic_unlock:before {
    content: "\e94a";
}
.ventiv-icon.icon-ic_lock:before {
    content: "\e949";
}
.ventiv-icon.icon-ic_global:before {
    content: "\e94b";
}
.ventiv-icon.icon-ic_lookup2:before {
    content: "\e94c";
}
.ventiv-icon.icon-ic_report:before {
    content: "\e94d";
}
.ventiv-icon.icon-ic_embed:before {
    content: "\e94e";
}
.ventiv-icon.icon-ic_linked:before {
    content: "\e94f";
}
.ventiv-icon.icon-ic_dup:before {
    content: "\e950";
}
.ventiv-icon.icon-ic_dashboardlist:before {
    content: "\e951";
}
.ventiv-icon.icon-ic_restrict:before {
    content: "\e959";
}
.ventiv-icon.icon-ic_multiple_doc:before {
    content: "\e95d";
}
.ventiv-icon.icon-ic_password_icon:before {
    content: "\e95e";
}
.ventiv-icon.icon-ic_view_info:before {
    content: "\e95f";
}
.ventiv-icon.icon-ic_user_info:before {
    content: "\e960";
}
.ventiv-icon.icon-ic_personal:before {
    content: "\e961";
}
.ventiv-icon.icon-ic_close_info:before {
    content: "\e962";
}
.ventiv-icon.icon-ic_history:before {
    content: "\e963";
}
.ventiv-icon.icon-ic_fliter:before {
    content: "\e964";
}
.ventiv-icon.icon-ic_cogno:before {
    content: "\e965";
}
.ventiv-icon.icon-ic_publish:before {
    content: "\e966";
}
.ventiv-icon.icon-ic_excel:before {
    content: "\e967";
}
.ventiv-icon.icon-ic_html:before {
    content: "\e968";
}
.ventiv-icon.icon-ic_pdf:before {
    content: "\e969";
}
.ventiv-icon.icon-ic_form_letter:before {
    content: "\e96a";
}
.ventiv-icon.icon-ic_xls_data:before {
    content: "\e96b";
}
.ventiv-icon.icon-ic_csv:before {
    content: "\e96c";
}

/* TODO This accounts for the fieldset changes in the normalize.css update
   Eventually fieldsets should be removed from the start pages */

fieldset {
    border: none;
    margin: 0 20px 0 20px;
}

/* !!!
    Class 2
    Styles originally in /businessObjectDesigner/css/business-object.css
    and /layoutDesigner/css/layout.css
    and /main/css/layout.css
    and /riskOneTools/css/layout.css
    and /riskOneTools/css/main.css
!!! */

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.property-editor-widget-box {
    border: 1px solid #000000;
    z-index: 1
}

.property-editor-widget-pos {
    float: right;
    max-width: 300px;
    overflow: visible;
    display: none;
}

.property-editor-widget-font {
}

.property-editor-widget-bgd {
    background-color: #ddd;
}

.property-editor-widget-pos .btn {
    min-width: 66px
}

.property-editor-widget-pos div {
    text-align: left
}

.last {
    margin-right: 0;
    padding-right: 0;
}

.box {
    padding: 1.5em;
    margin-bottom: 1.5em;
    background: #e5eCf9;
}

label b {
    display: block
}

.notice {
    padding: 0.8em;
    margin-bottom: 1em;
    border: 2px solid #ddd;
    background: #fff6bf;
    color: #514721;
    border-color: #ffd324;
}

.notice a {
    color: #514721;
}

.outlined-scrollbox {
    display: block;
    overflow: auto;
    border: 1px solid #ccc;
}

.action-button {

}

.action-button-pos {

}

.action-button-box {
    display: inline-block;
    border: 1px solid #ddd;
    margin: 2px;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-topleft: 0;
    border-top-left-radius: 0;
    -webkit-border-top-right-radius: 0;
    -moz-border-radius-topright: 0;
    border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-left-radius: 0;
}

.action-button-bgd {
    background-color: #efd;
}

.action-button-font {
    font-weight: bold;
    font-style: normal;
    text-indent: 0;
    text-decoration: none;
    color: #14396a;
}

.button.width120 {
    display: inline-block;
    width: 120px;
}

.button.widthall {
    width: 90%;
    display: inline-block;
}

.button.small {
    font-size: .8em;
    margin: 1px 0;
    padding: 0;
}

.button.medium {
    line-height: 1.2em;
    font-size: 1em;
    padding: 2px 10px;
    border: 1px solid #3866a3;
}

.button.large {
    height: 2em;
    line-height: 1.5em;
    font-size: 1.2em;
}

.button.white {
    background-color: #efd;
    color: #14396a;
    border: 1px solid #ddd;
    margin: 2px;
}

.button:hover {
    background-color: #dab;
}

.button:active {
    position: relative;
    top: 1px;
}

.action-button:hover {
    background-color: #dab;
}

.action-button:active {
    position: relative;
    top: 1px;
}

.drop-area {
    display: inline-block;
    height: 20px;
    padding: 2px 6px 0 6px;
    border: 1px solid #999;
    background: #ccc
}

.canvas-elem {
    border: 1px solid #ddd
}

.canvas {
    background: #efefef
}

.auto-width {
    width: auto;
}

.tabs {
    margin-bottom: 0;
    border-bottom-style: hidden;
    height: 820px;
}

.tabs :not(.active) a {
    color: #414141;
    border-width: 0;
    border-bottom-width: 0;
    border-top-width: 0;
}

.tabrow {
    font-family: var(--roboto);
    text-align: left;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: 24px;
    margin-top: 20px;
    line-height: 24px;
}

.tabrow li {
    box-sizing: content-box;
    width: 100px;
    margin: 0 10px 0 0;
    padding: 0 10px;
    background: #00548B;
    color: #FFF;
    display: inline-block;
    border-radius: 20px;
    text-align: center;
}

.tabrow .active {
    background: #80AAC6;
    color: #000;
}

.tabrow li:hover {
    opacity: .75;
    cursor: pointer;
}

.row-fluid {
    width: 100%;
}

.row-fluid:before,
.row-fluid:after {
    display: table;
    line-height: 0;
    content: "";
}

.row-fluid:after {
    clear: both;
}

.row-fluid [class*="span"] {
    display: block;
    float: left;
    width: 100%;
    min-height: 30px;
    margin-left: 2.127659574468085%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.row-fluid [class*="span"]:first-child {
    margin-left: 0;
}

.row-fluid .controls-row [class*="span"] + [class*="span"] {
    margin-left: 2.127659574468085%;
}

.nav {
    margin-bottom: 0;
    margin-left: 0;
    list-style: none;
    font-family: var(--roboto);
}

.nav > li > a {
    display: block;
}

.nav > li > a:hover {
    text-decoration: none;
    background-color: #eeeeee;
}

.nav > li > a > img {
    max-width: none;
}

.nav > .pull-right {
    float: right;
}

.nav li + .nav-header {
    margin-top: 9px;
}

.nav-tabs:before,
.nav-pills:before,
.nav-tabs:after,
.nav-pills:after {
    display: table;
    line-height: 0;
    content: "";
}

.nav-tabs:after,
.nav-pills:after {
    clear: both;
}

.nav-tabs > li,
.nav-pills > li {
    float: left;
}

.nav-tabs > li > a,
.nav-pills > li > a {
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 2px;
    line-height: 14px;
}

.nav-tabs {
    border-bottom: 1px solid #ddd;
}

.nav-tabs > li {
    margin-bottom: -1px;
}

.nav-tabs > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 20px;
    border: 1px solid transparent;
    font-family: var(--roboto);
    /*-webkit-border-radius: 4px 4px 0 0;*/
    /*-moz-border-radius: 4px 4px 0 0;*/
    /*border-radius: 4px 4px 0 0;*/
    -webkit-border-radius: 0 0 0 0;
    -moz-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0;
}

.nav-tabs > li > a:hover {
    border-color: #eeeeee #eeeeee #dddddd;
}

.nav-tabs > .active > a,
.nav-tabs > .active > a:hover {
    color: #414141;
    cursor: default;
    background-color: #FFFFFF;
}

.error-message {
    color: red;
    font-weight: bold;
}

/**Layout elements.**/
.insertion-point {
    height: 25px;
}

.insertion-point:hover {
    background: url('/Alpha/static/custom/layoutDesigner/images/insertion.png');
    background-repeat: no-repeat;
    background-position: 5px center;
    background-size: 15px 15px;
    background-color: #EFEFEF;
    height: 25px;
    background-image: url("/Alpha/static/custom/layoutDesigner/images/insertion.png");
}

.insertion-point-selected {
    background: url('/Alpha/static/custom/layoutDesigner/images/insertion.png');
    background-repeat: no-repeat;
    background-position: 5px center;
    background-size: 15px 15px;
    background-color: #EFEFEF;
    height: 25px;
    background-image: url("/Alpha/static/custom/layoutDesigner/images/insertion.png");
}

.layout-designer-group-field {
    margin: 5px;
    min-height: 25px;
    text-align: left;
    vertical-align: middle;
    line-height: 20px;
    font-size: 14px;
}

.field-drop-hover {
    background: url('/Alpha/static/custom/layoutDesigner/images/insertion.png');
    background-repeat: no-repeat;
    background-position: 5px center;
    background-size: 15px 15px;
    background-color: #EFEFEF;
    height: 25px;
    background-image: url("/Alpha/static/custom/layoutDesigner/images/insertion.png");
}

.column-dividing-line {
    min-height: 200px;
    border-right: 2px solid #CCCCCC;
    display: inline-block;
}

.margin-right {
    margin-right: 34px;
}

.margin-top {
    margin-top: 12px;
}

.panel-pos label {
    text-align: left;
    display: block;
    line-height: 15px;
}

.panel-pos fieldset {
    padding: 1.4em 0 1.4em 0
}

.hidden {
    display: none;
}

.right {
    text-align: right
}

.center {
    text-align: center
}

.canvas-container {
    display: block;
    height: 30px;
    width: 100%
}

.canvas1-2 {
    width: 49%;
    float: left;
}

.layout3col {
    float: left;
    width: 33%
}

.white-background {
    background: #FFFFFF
}

.canvas-button {
    padding: 2px 8px;
    border: 1px solid #ddd
}

.scrollbox {
    display: block;
    overflow: auto;
}

#fieldListId li:hover {
    cursor: pointer;
}

.plain-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: left;
}

.width120 {
    width: 120px
}

.width157 {
    width: 157px
}

.width200 {
    width: 225px
}

.width300 {
    width: 300px
}

.width500 {
    width: 500px
}

.height120 {
    height: 120px
}

.height15 {
    height: 15px;
}

.width150 {
    width: 150px;
}

.y-scroll {
    overflow-y: auto;
}

.display-inline {
    display: inline;
}

.pull-left {
    float: left;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: lighter;
}

.width100Percent {
    width: 100%;
}

/* !!!
    Class 1
    Styles originally in /main/css/theme-base.css
    and /riskOneTools/css/theme-base.css
    and /businessObjectDesigner/css/theme-base.css
    and /layoutDesigner/css/theme-base.css
!!! */

html {
    -webkit-font-smoothing: antialiased;
    margin: 0;
    background-color: #fff;
}

.page-font {
    font-weight: normal;
    text-decoration: none;
    font-style: normal;
    font-size: 1.0em;
    line-height: 0.7;
    color: #000000;
    font-family: var(--roboto);
}

.page-bgd {
    background: #828C8E;
}

.page-box {
    margin: 10px 0;
    padding: 0;
    border: 0;
}

.page-pos {
}

.page-header-font {

}

.page-header-bgd {
    background: #002733;
}

.page-header-box {
    vertical-align: middle;
}

.page-header-pos {
}

.page-header-logo-font {

}

.page-header-logo-bgd {
    background: url('/Alpha/static/custom/layoutDesigner/images/worldwide_corp_logo.png') no-repeat left center;
}

.page-header-logo-box {
    margin: 0 0 10px 4px;
    height: 50px;
    width: 140px;
}

.page-header-logo-pos {
    float: left;
}

.page-header-menu-font {
}

.page-header-menu-bgd {
}

.page-header-menu-box {
    margin: 10px 10px
}

.page-header-menu-pos {
    float: right;
    margin-top: -2px;
}

.page-header-menu-item-font {
    color: white;
    text-decoration: none;
}

.page-header-menu-item-bgd {

}

.page-header-menu-item-box {
    margin: 0 10px;
    padding: 0;
}

.page-header-menu-item-pos {

}

.header-font {
    font-family: var(--roboto);
    font-style: normal;
    font-variant: normal;
    color: #FFFFFF;
    padding: 1.5em 0;
    font-size: 2em;
    margin-bottom: 0.75em;
    color: #FFFFFF;
}

.header-bgd {
    background: none repeat scroll 0 0 #333333;
}

.header-box {
    padding: 16px;
    margin: 0;
    padding-bottom: 15px;
    padding-left: 12px;
    padding-right: 12px;
}

.header-pos {

}

.sub-header-font {
    font-size: 1.5em;
    line-height: 1;
    margin-bottom: 1em;
}

.sub-header-bgd {
    background: #E7F5FF;
}

.sub-header-box {
    margin: 0 auto;
    padding: 10px 0;
}

.sub-header-pos {

}

.footer-font {

}

.footer-bgd {
    background: #ddd;
}

.footer-box {

}

.footer-pos {

}

.tab-pos {

}

.tab-font {
}

.tab-bgd {
}

.toolbar-font {

}

.toolbar-box {

}

.toolbar-bgd {

}

.toolbar-pos {

}

.lbl-box {}
.lbl-font {}
.lbl-pos {}
.lbl-bgd {}

.input-field-lbl {
    vertical-align: middle;
    font-family: var(--roboto);
    font-size: 13px;
    font-style: normal;
    font-variant: normal;
    text-align: left;
    font-weight: normal;
}

.input-field {
    display: block;
    text-align: start;
    font-family: var(--roboto);
    font-size: 11pt;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    color: #000000;
    height: auto;
    margin: 0;
    padding-top: 2px;
    padding-right: 0;
    padding-bottom: 2px;
    padding-left: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    background-color: #fcfcfd;
    background-image: none;
    border-radius: 2px;

}

.input-field-lbl {
    color: #9b9b9b;
    display: block;
    margin-top: 20px;
    margin-bottom: 5px;
}

.input-field.readonly {
    background: #eeeeee;
    border: 0;
}

.textarea-box {
    line-height: normal;
}

.number-box {
}

.number-bgd {
}

.number-font {
}

.number-pos {
    text-align: right;
}

.number-lbl-box {
}

.number-lbl-bgd {
}

.number-lbl-font {
}

.number-lbl-pos {
}

.string-box {
}

.string-bgd {
}

.string-font {
}

.string-pos {
}

.string-lbl-box {
}

.string-lbl-bgd {
}

.string-lbl-font {
}

.string-lbl-pos {
}

.dropdown-pos {
    margin-left: -10px;
}

.dropdown-box {
    padding: 0;
}

.dropdown-bgd {
}

.dropdown-font {
}

.dropdown-lbl-pos {
    margin-left: -154px;
}

.dropdown-lbl-box {
    padding: 0;
}

.dropdown-lbl-bgd {

}

.radio-pos {
}

.radio-box {
}

.radio-bgd {
}

.radio-font {
    color: #000000;
}

.radio-lbl-pos {
}

.radio-lbl-box {
}

.radio-lbl-bgd {
}

.radio-lbl-font {
}

.dropdown-lbl-font {
}

.lookup-lib-box {
}

.lookup-lib-bgd {
}

.lookup-lib-font {
}

.lookup-lib-pos {
    text-align: left;
}

.document-download-input,
.hyperlink-box {
    display: inline-block;
    padding-left: 0;
}

.digital-link-box {
    display: inline-block;
    padding-left: 0;
    cursor: pointer;
}

.document-download-input,
.hyperlink-font {
    color: #148dce;
    font-weight: 700;
    text-decoration: underline;
}

.hyperlink-font.digital-link, .hyperlink-font.digital-link-box {
    font-size: 13px;
    cursor: pointer;
}

.panel-pos {
    text-align: center;
    line-height: 15px;
}

.panel-box {
    margin: 0 auto;
}

.panel-bgd {
    background-color: #CCCCCC;
}

.panel-font {

}

.layout-panel-pos {
    text-align: left;
}

.layout-panel-box {
    padding: 20px 0;
    margin: 0 auto;
    height: 100%;
}

.layout-panel-bgd {
    background-color: #fff !important;
}

.layout-panel-font {
}

.tab-container-pos {
    text-align: center;
}

.tab-container-box {
    margin: 12px;
    background-color: #CCCCCC;
    margin-right: 12px;
    margin-top: 12px;
}

.tab-container-bgd {
    background-color: #CCCCCC;
}

.tab-container-font {
}

.divider-pos {
    text-align: center;
}

.divider-box {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
}

.divider-bgd {
    background-color: #FFFFFF;
}

.divider-font {
}

.section-pane-font {
    color: #000000;
}

.section-pane-pos {
}

.section-pane-box {
}

.section-pane-bgd {
    background-color: #FFFFFF;
}

.section-pane-link-font {
}

.section-pane-link-pos {
    text-align: left;
    margin: 5px;
}

.section-pane-link-box {
}

.section-pane-link-bgd {
}

/*********** Taken from bootstrap ***************/
.input-append.date .add-on i, .input-prepend.date .add-on i {
    display: inline-block;
}

.btn-box {
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 0;
    margin-right: 12px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: center;
    min-width: 80px;
}

.btn-font {
    /*font-family: 'Segoe UI Light',"Segoe UI", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue";*/
    font-family: var(--roboto);
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
    text-shadow: 0 1px 1px #FFFFFF, 0.75;
    vertical-align: middle;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

.btn-pos {
    height: 30px;

}

.second-level-btn-font {
    font-family: var(--roboto);
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
    text-shadow: 0 1px 1px #FFFFFF, 0.75;
    vertical-align: middle;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}


.second-level-btn-pos {
    height: 24px;
}

.second-level-btn-box{
    display: inline-block;
    margin-bottom: 0;
    margin-right: 6px;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
    text-align: center;
    min-width: 80px;
    border: 0;
}

.third-level-btn-font {
    font-size: 12px;
    text-align: center;
}
.third-level-btn-box {
    padding: 3px;
    border: 1px solid #CCCCCC;
    border-bottom: 2px solid #AAAAAA;
}
.third-level-btn-bkg {
    background: linear-gradient(#FFFFFF, #CCCCCC);
}
.third-level-btn-font:disabled {
    color: #ffffff;
}

.add-remove-btn-font {
    font-family: var(--roboto);
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
    text-shadow: 0 1px 1px #FFFFFF, 0.75;
    vertical-align: middle;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}


.add-remove-btn-pos {
    height: 32px;
}

.add-remove-btn-box{
    display: inline-block;
    margin-bottom: 0;
    margin-right: 12px;
    padding-bottom: 5px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: center;
    min-width: 41px;
    border: 0;
}

.main-btn-box {
    display: inline-block;
    padding: 4px 12px;
    margin-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: center;
}

.main-btn-bgd {
    background-color: #FFFFFF;
}

.main-btn-font {
    font-family: var(--roboto);
    font-size: 16px;
    line-height: 20px;
    color: #808080;
    text-align: center;
    text-shadow: 0 1px 1px #808080, 0.75;
    vertical-align: middle;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

.main-btn-pos {
    height: 30px;

}

.layout-page-font {
    font-size: 75%;
    color: #222;;
    font-family: var(--roboto);
}

.layout-page-box {
    height: 95%;
}

.layout-page-bgd {
    background-color: #FFFFFF;
}

.layout-grp-font {
    font-weight: normal;
    text-decoration: none;
    font-style: normal;
    font-size: 1em;
    line-height: 1;
    color: #000000
}

.layout-grp-bgd {
    background-color: #FFFFFF;
}

.layout-grp-box {
    border-radius: 0;
}

.layout-grp-header-font {
    font-weight: normal;
    text-decoration: none;
    font-style: normal;
    font-size: 1.5em;
    line-height: 1;
    text-align: left;
    color: #C3DBE6
}

.layout-grp-header-bgd {
    background-color: #ffffff;
}

.layout-grp-header-box {
}

.layout-grp-footer-font {
    font-weight: normal;
    text-decoration: none;
    font-style: normal;
    font-size: .8em;
    line-height: 1;
    color: #000000
}

.layout-grp-footer-bgd {
    background-color: #ffffff;
}

.layout-grp-footer-box {
    margin: 0;
    padding: 5px;
    border: 0;
}

.modal-backdrop {
    opacity: 0;
}

.timeout-login-modal.modal-backdrop.in {
   opacity: 1;
}

/************* END taken from bootstrap ****************/

.height550 {
    height: 550px;
}

.height650 {
    height: 650px;
}

.height620 {
    height: 620px;
}

.height100-percent {
    height: 100%;
}

/* Padding for IE & FF */
@-moz-document url-prefix() {
    .layout-panel-box {
        padding: 0 0 1.5px;
    }
}
/*********************End Used by UI Layout . moved to static/custom/common/uilayout.css*****************/
.layout-panel-box .header-box {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    height: auto;
    background-color: #fff;
    color: #0071bc;
}

.header-box .string-font {
    color: #FFF
}

.modal-pos {

}

.modal-font {
    text-align: center;
    color: #000000;
}

.modal-bgd {
    background-color: #ffffff;
}

.modal-toolbar-bgd {
    background-color: #ffffff;
}

.modal-toolbar-box {
    display: inline;
    padding-bottom: 10px;
}

.modal-toolbar-font {
    text-align: center;
}

.img-box {
    height: 20px;
    width: 20px;
}

.height65 {
    height: 65px;
}

.pad10 {
    padding: 10px;
}

.layout-workspace-bgd {
    /*background-color: #CCEBFF;*/
    background-color: white;
}
.layout-workspace-box {
    margin: 5px;
    padding: 5px;
}

/* !!!
    Class 3
    Styles originally in /main/css/app.css
    and /riskOneTools/css/app.css
!!! */

/*
    Table of contents
    ****
    * top of the cascade - should affect everything but may be overridden
    ****
        animation
        top level elements

    ****
    * classes for this application, may be overridden
    ****
        application styles

    ****
    * styling for specific widgets / sections, should not be overridden
    ****
        d3js graph
        calculator table
        breadcrumb navbar
        dashboard

    ****
    * tooltips must not be overridden
    ****
        tooltip

    ****
    * bottom of the cascade
    ****
        overrides, unitary
        id styles
*/



/**
 * =animation
*/
.view-enter, .view-leave {
    -webkit-transition:all cubic-bezier(0.165, 0.840, 0.440, 1.000) 0.5s;
    -moz-transition:all cubic-bezier(0.165, 0.840, 0.440, 1.000) 0.5s;
    -o-transition:all cubic-bezier(0.165, 0.840, 0.440, 1.000) 0.5s;
    transition:all cubic-bezier(0.165, 0.840, 0.440, 1.000) 0.5s;
}


.view-enter {
    opacity:0;
    left:100px;
    width:100%;
    position:absolute;
}
.view-enter.view-enter-active {
    left:0;
    opacity:1;
}

.view-leave {
    position:absolute;
    left:0;
    width:100%;
    opacity:1;
}
.view-leave.view-leave-active {
    left:-100px;
    opacity:0;
}
.animate-enter {
    -webkit-transition-duration: 500ms;
    -webkit-transition-timing-function: ease-in-out;
    -webkit-transition-property: all;
    -webkit-transition-delay: 0;
    overflow: hidden;
    display: block;
    opacity: 0;
}
.used-class {
    color: blue;
}
.animate-enter.animate-enter-active {
    opacity: 1;
}

.animate-leave {
    -webkit-transition-duration: 50ms;
    -webkit-transition-timing-function: ease-in-out;
    -webkit-transition-property: all;
    -webkit-transition-delay: 0;
    overflow: hidden;
    opacity: 1;
}
.animate-leave.animate-leave-active{
    opacity: 0;
}

/*
    Top level elements
*/

.container-inside {
    min-height: 100%;
    margin: auto;
    min-width: 1005px;
    overflow-x: hidden;
    overflow-y: hidden;
}


body {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
}
.noscroll {
    overflow: hidden;
}

a:hover {
    -moz-text-blink: none;
    -moz-text-decoration-line: none;
    -moz-text-decoration-style: solid;
    text-decoration: none ;
}
/* end top level elements */

/*
    =application styles
*/

.reveal-tile {
    color: white;
    padding-left: 10px;
    line-height: 100%;
}

.reveal-tile-title-text {
    font-size: 18pt;
    padding-top: 15px;
    color: #ffffff;
    padding-left: 20px;
}

.reveal-tile-element {
    color: white;
    padding-top: 5px;
}

.tile-text {
    margin-left: 0;
    padding-left: 0;
}

.reveal-tile-column-element {
    color: white;
    font-size: 10pt;
}

.reveal-tile-add {
    background-image: url("/Alpha/static/custom/main/images/add_tile_icon.png");
    width: 33px;
    height: 33px;
    left: 90px;
    position: relative;
    margin-top: -5px;
}

.reveal-tasks-li {
    font-size: 11pt;
    margin-top: 9px;
    margin-bottom: 9px;
}

.begin-image {
    padding-top: 25px;
    padding-left: 100px;
    cursor: pointer;
}

.image-padding{
    margin-right: 10px;
    margin-top: 6px;
}

.image-size{
    height: 80px;
    width: 100px;
}

.tcor-header {
    height: 70px;
    min-height: 70px;
    width: 100%;
}

.tcor-tiles-container {
    background-color: #002834;
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
    min-width: 985px;
    line-height: 18px;
}

.tcor-tile {
    margin: 6px;
    float: left;
}

.bar-graph-image {
    max-height: 300px;
    height: 235px;
    width: 490px;
    padding-left: 47px;
}

.db-graph-image {
    height: 255px;
    width: 426px;
}

.calendar-image {
    /*height: 260px;*/
    /*width: 310px;*/
    /*padding-left: 46px;*/
    /*padding-top: 20px;*/
}

.landing-background {
    margin: 10px;
    background-color: #002733;
}

.tcor-tile-1 {
    background-color: #00558E;
    color: #ffffff;
    max-height: 277px;
}

.tcor-tile-2 {
    background-color: #004467;
    height: 277px;
}

.tcor-tile-3 {
    background-color: #862C60;
    width: 34%;
    height: 290px;
}

.tcor-tile-4 {
    background-color: #5c8029;
    height: 290px;
}

.tcor-tile-5 {
    background-color: #614991;
    width: 22%;
    height: 290px;
}

.tcor-tile-6 {
    background-color: #87292d;
    height: 190px;
}

.tcor-tile-7 {
    background-color: #006090;
    height: 88px;
}

.align-wizard-elements {
    margin-left: 140px;
    margin-right: 20px;
    padding-top: 40px;
}

.white-title {
    margin-top: 15px;
    font-size: 24pt;
    color: white;
    padding-left: 20px;
    line-height: 25px;
}
.read-more > a, .re-collapse > a {
    text-decoration: none;
}
.text-color-white{
    color: white;
    text-decoration: none;
}

.risk-retention-opts {
    height: 70px;
    line-height: 20px;
    margin-top: -18px;
    text-align: left;
    left-margin: -10px;

}
.risk-button {
    background-color: #E6F0F9;
    width: 460px;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid lightblue;
    text-align: left;
    padding-left: 10px;
    margin: 0 auto 10px;
}

.risk-button-text {
    font-size: 12pt;
    line-height: 30px;
    padding-left: 10px;
}

.tcor-div {
    width: 460px;
    height: 48px;
    line-height: 25px;
    bottom: 45px;
    left: 10px;
    position: absolute;
    padding: 10px;
}

.calculator-container3 {
    min-width: 600px;
    width: 600px;
    margin-left: 300px;
}

.retention-list {
    border-top: 1px lightblue solid;
    padding-top: 2px;
}

.retention-list-item {
    list-style: none;
    pointer-events: auto;
    line-height: 20px;
    padding-top: 0;
    margin-left: -10px;
}

.allocation-body {
    position: relative;
    max-height: 380px;
    width: 470px;
}


.instruction-label {
    margin-top: 14px;
    margin-bottom: 20px;
    padding-left: 20px;
    font-weight: bold;
}

.summary-table {
    margin-left: -10px;
    width: 430px;
}

.computed-value {
    background-color: lightgoldenrodyellow;
}

.step3-header {
    background-color: #E6E6E6;
    width: 33%;
    border: 1px solid #ffffff;
    vertical-align: middle;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 90%;
}

.step3-row {
    text-align: left;
    padding-left: 10px;
}

.step3-value {
    width: 33%;
    text-align: center;
    border: 1px solid #E6E6E6;
    font-size: 14px;
}

.step-5-table {
    margin-top: 14px;
    margin-bottom: 20px;
    font-weight: bold;
    padding-left: 10px;
}

.check-image {
    height: 200px;
    width: 215px;
    position: relative;
    top: -30px;
}
.summary-header {
    border: 1px solid #ffffff;
    font-size: 14px;
    background-color: #e6e6e6;
}

.summary-value-box {
    width: 50%;
    text-align: center;
    border: 1px solid #E6E6E6;
}

.forward-nav-button {
    width: 190px;
}
.footer-button, .footer-button:hover  {
    border-radius: 0;
    width: 95px;
    font-size: 14px;
    font-weight: normal;
}
input.allocation-numeric {
    width : 78px;
    text-align: right;
    vertical-align: middle;
    padding-right: 2px;
}

td.allocation-currency {
    width : 90px;
}

.speedo-image {
    height: 80px;
    width: 130px
}

.wizardListMain {
    margin-left: 10px;
    margin-right: 10px;
}

.planningHeader {
    background-color: #CEE8F7;
    height: 55px;
}

td.estimate-field {
    height: 55px;
    width: 400px;
    padding-left: 25px;
    vertical-align: middle;
}

.word-wrap {
    width: 18%;
}

.slider-legend {
    font-size: 12pt;
    color: #000000;
}

.flat-legend {
    margin-left: 32px;
    width: 80px;
}

.left-legend {
    margin-left: 18px;
}

.slider-label-box {
    padding-left: 0;
    margin-bottom: 40px;
}

/* needed to align number over slider pointer */
output {
    position: relative;
    width: 40px;
    height: 30px;
    text-align: center;
    border-radius: 10px;
    display: inline-block;
    left: 0;
    margin-left: -1.8%;
    color: #000000;
}

.planned-results {
    height: 45px;
    margin: 0 auto 20px 10px;
    position: absolute;
    top: 280px;
    width: 435px;
}

.planned-results-label {
    line-height: 10px;
    padding-left: 10px;
}

.planned-result {
    float: left;
    line-height: 10px;
    padding-left: 20px;
    margin-top: 20px;
}

.current-loss-costs-label {
    line-height: 20px;
    padding-left: 10px;
    margin-top: 4px;
}

.current-loss-costs-div {
    height: 60px;
    margin: 0 auto 20px;
    margin-left: 10px;
    position: absolute;
    top: 200px;
    width: 435px;
    padding-top: 5px;
}

.current-loss-cost {
    padding-left: 35px;
    margin-top: 10px;
}

.allocation-table {
    margin: 15px 20px 0 20px;
}

/*********** Wizard Panel CSS ***************/
.wizard-panel-component {
    background-color: white;
}

.wizard-panel-header-div {
    height: 50px;
    max-height: 50px;
    line-height: 50px; /* = height, vertically center <p> text */
}

.wizard-panel-header {
    font-size: 22pt;
    color: #0071bc;
    margin: 10px 0 0;
    padding-left: 20px;
}

.wizard-panel-content-div {
    height: 345px;
}

.tcor-planning-content {
    margin-top: 30px;
}

.text-div {
    border: 1px solid lightgray;
}
/********* End Wizard Panel CSS *************/

.allocation-div {
    width: 220px;
    text-align: center;
    line-height: 0;
    margin: 3px;
    font-size: 13pt;
    color: #ffffff;
}

.allocation-outer-circle {
    color: #fff;
    background-color: #fff;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    margin-left: 10px;
    margin-top: -8px;
    margin-right: 10px;
}

.allocation-inner-circle {
    color: #0071BD;
    background-color: #0071BD;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-top: 4px;
    margin-left: 4px;
}

.allocation-move-text {
    text-align: center;
    color: #000000;
    margin-top: 15px;
    margin-bottom: 20px;
}

.allocation-text {
    text-align: left;
    line-height: 0;
    font-size: 13pt;
    color: #ffffff;
}

.allocation-amount-input {
    margin-bottom: 3px;
    margin-top: 3px;
}

.optimal-results-div {
    width: 150;
    height: 20;
}

.move-text-div {
    width: 465;
    height: 20;
    margin: 15px 0 20px 0;
}

.input-field-pre {
    font-weight: normal;
    font-size: 12pt;
    vertical-align: top;
}

/* Override bootstrap.css style for <input type="text"> element. */
#reviewEntriesTable .step-6-input {
    margin-bottom: 2px;
    margin-top: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
}

.step-5-symbol-alignment {
    vertical-align: middle;
    padding-top: 2px;
    padding-left: 5px;
    text-align: right;
}

.track-plan {
    height: 120px;
    margin-left: 110px;
}

.radios {
    border: none;
}

.five-content {
    border: none;
    margin: 0;
}

.no-border {
    border: none;
}

.debit-table {
    border-collapse: separate;
    border-spacing: 4px;
}

.modify-plan-button {
    margin-top: 165px;
    width: 116px; /* width of image */
    background-image: url("/Alpha/static/custom/main/images/button-modify.png");
}

.progress-detail-button {
    width: 145px; /* width of image */
    background-image: url("/Alpha/static/custom/main/images/button-progress-detail.png");
    margin-left: 20px;
    margin-top: 80px;
    height: 35px;
    line-height: 30px;
    padding: 0;
}

.padding-60 {
    padding-left: 75px;
    padding-top: 60px;
}

.exclamation-bullet-inside {
    padding-top: 3px;
    list-style-type: none;
    list-style: url("/Alpha/static/custom/main/images/exclamation-bullet.png") inside;
}

.empty-bullet-inside {
    list-style-type: none;
    list-style: url("/Alpha/static/custom/main/images/empty-bullet.png") inside;
}

.exclamation-bullet:before {
    margin-top: 3px;
    display: inline-block;
}

.bullet {
    padding-left: 5px;
    list-style-image: url("/Alpha/static/custom/main/images/exclamation-bullet.png");
}

.empty{
    padding-left: 5px;
    list-style-image: url("/Alpha/static/custom/main/images/empty-bullet.png");
}

fieldset {
    padding: 0 0.625em 0;
}

.allocation-header {
    display: inline-block;
    font-size: 10pt;
    height: 20px;
    line-height: 20px;
    margin: 0 0 10px 0;
    padding: 10px 10px 10px 20px;
    font-weight: bold;
}

/* end application styles */

/*
    =d3js graph
*/
.axis path,
.axis line {
    fill: none;
    stroke: #3C74AF;
    stroke-width: 1;
    shape-rendering: crispEdges;
}

.xaxis {
    fill: none;
    stroke: #3C74AF;
    stroke-width: 1;
    shape-rendering: crispEdges;
}

.yaxis {
    fill: none;
    stroke: #3C74AF;
    stroke-width: 1;
    shape-rendering: crispEdges;
    color: orange
}

.area {
    fill: url(#path-gradient);
}

.area2 {
    fill: url(#path-gradient2);
}

.grid .tick {
    stroke: #add8e6;
    opacity: 0.7;
}

.grid path {
    stroke-width: 0;
}


.median {
    stroke: #ffffff;
    stroke-width: 1;
    fill: none;
}

.margin-left-50 {
    margin-left: 50px;
}

.dial-text {
    font-size: 21pt;
}

/* end d3js graph */

/*
   =calculator table
*/
.calculator-container {
    min-width: 200px;
    width: 200px;
    margin-left: 200px;
}

#calculatorTypeBtnGroup {
    margin-top: 100px;
}

#calculatorTable {
    font-size: 12px;
    width: 380px;
    margin-top: 5px;
    text-align: right;
}

/* center-align numbers in cells */
#calculatorTable td + td {
    text-align: center;
}

#calculatorTable th {
    font-size: 14px;
    font-weight: normal;
    padding: 12px 15px;
    color: #039;
    background-color: darkgrey;
}

#calculatorTable td {
    text-align: right;
    padding: 10px 15px;
    color: #669;
    border: 1px solid cornflowerblue;
}

/** Column hover */
.highlightedColumn {
    color: #339;
    background: #eff2ff;
}

#calculatorButtonRow {
    width: 650px;
}

#miniGraph {
    margin-left: 30px;
    overflow: hidden;
    border: 1px solid cornflowerblue;
}
/* end calculator table */

/*
    =breadcrumb navbar
*/
.breadcrumb-bar {
    width: 980px;

}
.bcrumb {
    float: left;
    padding-left: 4px;
    width: 146px;
    min-width: 146px;
}
.breadcrumb-div {
    height: 40px;
    width: 100%;
}

.breadcrumb-list {
    list-style-type: none;
}

.breadcrumb-image{
    height: 20px;
    width: 20px;
}

#breadcrumbs-one{
    border-width: 0 0 1px;
    height: 30px;
    border-style: solid;
    border-bottom: 1px none #157CBF;
    overflow: hidden;
    width: 1024px;
    counter-reset: flag;
}

#breadcrumbs-one li{
    float: left;

}

#breadcrumbs-one a{
    float: left;
    text-decoration: none;
    color: #157cbf;
    position: relative;
    padding: 5px 20px;
}

#breadcrumbs-one li:first-child a{
    padding-left: -1em;
}

#breadcrumbs-one a::after,
#breadcrumbs-one a::before{
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -20px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 1em solid;
    right: -1em;
}

#breadcrumbs-one a::after{
    z-index: 2;
}

#breadcrumbs-one a::before{
    right: -1.1em;
    z-index: 1;
}

#breadcrumbs-one .current{
    background: #108cd0 linear-gradient(to right, #25a7ee, #108cd0);
    color: #ffffff;
}

#breadcrumbs-one .current::after{
    border-left-color: #108cd0;
}

#breadcrumbs-one .inactive{
    background: none repeat scroll 0 0 #9CA4A6;
    color: #ffffff;
}

#breadcrumbs-one .first{
    height: 30px;
    color: #157cbf;
    background: none repeat scroll 0 0 #E7F6FF;
    margin-left: -40px;
}

#breadcrumbs-one .first::after{
    border-left-color: #e7f6ff;
}

#breadcrumbs-one a:after {
    border-left-color-value: #9CA4A6;
}

#breadcrumbs-one a:before {
    border-left-color-value: #CCCCCC;
    right: -1.1em;
}

#breadcrumbs-one a:after, #breadcrumbs-one a:before {
    border-bottom-width: 16px;
    border-top-width: 16px;
    margin-top: -16px;
    border-bottom-color: transparent;
    right: -1em;
}

/* end breadcrumb navbar */

/*
    =dashboard
*/

#graphImage {
    height: 255px;
    float: right;
    margin-right: 10px;
    margin-top: 7px;
    margin-bottom: 40px;
}

#occurrencesDiv {
    font-size: 10px;
}

.db-add-widget-img {
    margin-top: -10px;
    margin-left: 0;
}

.db-dial-left {
    margin-right: 5px;
    width: 120px;
}

.db-dial-right {
    margin-left: 5px;
    width: 120px;
}

.db-graph-2-img {
    margin-left: 122px;
    width: 160px;
    height: 160px;
    margin-top: -115px;
}

#db_tile5_pageId {
    margin-top: 3px;
    margin-left: 1px;
    margin-right: 1px;
}

.db_tile {
    margin-right: 20px;
}

.db_tile1 {
    float: left;
    height: 80px;
    line-height: 35px;
    font-size: 20pt;
    margin-top: -15px;
}

.db-tcor-tile-1 {
    background-color: #006090;
    color: #ffffff;
    width: 54%;
    max-height: 270px;
}

.db-tcor-tile-2 {
    background-color: #003D66;
    width: 427px;
    height: 270px;
}

.db-tcor-tile-3 {
    background-color: #862C60;
    width: 32%;
    height: 300px;
}

.db-tcor-tile-4 {
    background-color: #5c8029;
    width: 20.8%;
    height: 300px;
}

.db-tcor-tile-5 {
    background-color: #006090;
    width: 427px;
    height: 170px;
}

.db-tcor-tile-6 {
    background-color: #87292d;
    width: 207px;
    height: 118px;
}

.db-tcor-tile-7 {
    background-color: #006090;
    width: 208px;
    height: 118px;
}

#addWidgetDiv > ul {
}

#addWidgetDiv > ul > li {
    line-height: 35px;
}

/* end dashboard */

/*
    =tooltip
*/
.qtip-tooltip {
    border: 1px solid #000000;
    font-size: 15px;
    background-color: #ffffff;
    box-shadow: 10px 10px 5px #888;
    border-radius: 3px;
    text-align: left;
    opacity: 1;
    line-height: 19px;
}
.qtip-content {
    color: #000000;
}
.qtip-tip {
}

/* end tooltip */


/*
    =overrides, unitary classes
    These classes should have generic names only
*/

.white-text {
    color: white;
}

.black-text {
    color: black;
}

.cursor-pointer {
    cursor: pointer;
}

.inline-block {
    display: inline-block;
}

/* badly named */
.wizard-body-label-text {
    padding-left: 10px;
}

.center-text {
    text-align: center;
}

.right-text {
    text-align: right;
}

.middle-vtext {
    vertical-align: middle;
}

.align-header {
    padding: 15px 0 0 15px;
}

.disable-pointer-events {
    pointer-events: none;
}

.margin-right-13-px {
    margin-right: 13px;
}

.margin-left-15-px {
    margin-left: 15px;
}

.margin-left-neg-10-px {
    margin-left: -10px;
}

.margin-left-neg-13-px {
    margin-left: -13px;
}
.allocation-div-not-selected {
    background-color: #B3B3B3;
}

.allocation-div-selected {
    background-color: #0071BD;
}

.allocation-div:hover {
    background-color: rgba(8, 128, 209, 0.9);
}

.allocation-text-div {
    margin-right: 10px;
}

.font-size-11 {
    font-size: 11pt;
}

.tile-list{
    list-style-type: none;
}

.step-2-subhead {
    margin-top: -10px;
}

.pad-adjust {
    padding-bottom: -5px;
}

.step-6-instruction-label {
    margin-bottom: 10px;
}

.step-6-subtitle {
    margin-top: -15px;
}

.modify-plan-text {
    margin-left: 10px;
}

.font-size-12 {
    font-size: 12pt;
}

.width-100-pct {
    width: 100%;
}

.add-tile {
    padding-top: 5px;
}


.step-4 {
    margin-top: -14px;
}

.step-5 {
    margin-top: -14px;
}
/* end overrides, unitary */

/*
    =id styles
*/
#headerRowList {
    text-align: right;
    list-style-type: none;
}

#headerRowList li {
    white-space: nowrap;
}

#calculatorTable3 {
    font-size: 12px;
    margin-top: 5px;
    text-align: right;
}

#calculatorTable3 th {
    font-size: 14px;
    font-weight: normal;
    padding: 12px 15px;
    color: #039;
    background-color: #F5F5DC;
}

#calculatorTable3 td {
    text-align: center;
    padding: 10px 15px;
    color: #669;
    border: 1px solid cornflowerblue;
}

#tableDiv {
    width: 157px;
    margin-left: 30px;
    overflow-x: scroll;
}

#calculatorButtonRow3 {
    width: 550px;
    margin: 0 10px 10px 10px;
}

#ngSwitchDiv {
    width: 465px;
    height: 340px;
}

#allocationWizard {
    z-index: 1000;
    position: absolute;
    top: 50px;
    left: 150px;
    width: 700px;
    height: 600px;
    opacity: 0.85;
    -moz-opacity: 0.85;
    filter: alpha(opacity=85);
    visibility: hidden;
}

#allocationWizard2 {
    width: 480px;
    height: 380px;
    overflow: hidden;
}

#graphControlRow {
    margin-top: 20px;
}

#riskButtonDiv {
    background-color: white;
    height: 380px;
    position: relative;
    overflow: hidden;
}

#riskRetentionCostsDiv {
    background-image: -webkit-linear-gradient(top, #E6F0F9, #ffffff);
    background-image: -moz-linear-gradient(top, #E6F0F9, #ffffff);
    background-image: -o-linear-gradient(top, #E6F0F9, #ffffff);
    background-image: linear-gradient(top, #E6F0F9, #ffffff);
    margin-left: -10px;
}

#riskRetentionCostsListDiv ul li:hover {
    cursor: default;
    color: darkblue;
    background-color: #ebf5fb;
}

#riskRetentionCostsListDiv ul li:before {
    content: "+";
    padding-right: 5px;
}

#sliderDiv {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
}

#slider1Id {
    width: 95%;
    margin-left: 10px;
    cursor: pointer;
}

#selectYearId {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: #ccc;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #ddd), color-stop(0.6, #eee));
    background-image: -webkit-linear-gradient(center bottom, #ddd 0%, #eee 60%);
    background-image: -moz-linear-gradient(center bottom, #ddd 0%, #eee 60%);
    background-image: -o-linear-gradient(bottom, #ddd 0%, #eee 60%);
    background-image: -ms-linear-gradient(top, #dddddd 0%, #eeeeee 60%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#dddddd', endColorstr = '#eeeeee', GradientType = 0);
    background-image: linear-gradient(top, #dddddd 0%, #eeeeee 60%);
    width: 95px;
    overflow: hidden;
    border: 1px solid #ccc;
    margin: 15px 0 0 10px;
    padding-bottom: -5px;
    height: 39px;
}

#mainLink {
    cursor: default;
}

#totalCostOfRiskField {
    width: 110px;
    margin-right: 10px;
}

#tcorText {
    margin-top: 3px;
    margin-left: 20px;
}


.mainAnchorHeading {
    font-size: 13px;
    font-weight: bolder;
    float: left;
}
.dt-mr-1{
    display: none;
}
.dt-mr{
    margin-right: 15px !important;
}
.titleBarColor{
    display: flex;
    gap: 10px;
    align-items: center;
}
@media not all and (min-width:  1530px){
    .navbar-ul{
        width: 100%;
    }
    .navbar-nav>li>a {
        padding-right: 8px;
        padding-left: 8px;
    }
    .dt-mr-1{
        display: block !important;
        margin-right: 15px !important;
    }
    .dt-mr{
        display: none;
    }
}
.hamburger-menu{
    display: none;
}
.navbar-ul{
    display: block;
}
@media (min-width: 0px) and (max-width: 1110px) {
    .hamburger-menu{
        position: absolute;
        right: 70px;
        text-decoration: none;
        color: white;
        display: block;
        top: 10px;
        font-size: 15px;
        text-align: center;
        /*padding: 5px 8px;*/
    }
    .hamburger-menu:hover{
        border: #ccc dashed 1px;
    }
    .hamburger-menu:hover > .glyphicon,.hamburger-menu:visited > .glyphicon{
        color: #FFFFFF;
        cursor: pointer;
    }
    .hamburger-menu .glyphicon{
        font-size: 16px;
        cursor: pointer;
        padding: 10px;
    }
    .navbar-ul{
        top:55px;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        right: 0px;
        z-index: 1000;
        width: 170px;
        height: 100vh;
        background: #000;
        transition:all .5s ease;
        padding: 10px 0px 10px 20px;
    }
    .alpha-main-nav .nav{
        margin-right: 0px !important;
    }
    .navbar-nav>li>a {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .navbar-ul ul:first-child{
        order: 2;
    }
    .navbar-ul ul:last-child{
        order: 1;
    }
    .navbar-ul .dropdown-menu{
        position: absolute !important;
        background: white !important;
        top: 35px;
    }
    .favorites-menu{
        max-height: 250px !important;
    }
    .hamburger-menu .glyphicon-menu-hamburger{
        display: none;
    }
    .dt-mr-1{
        display: block !important;
        margin-right: 40px !important;
        align-items: flex-start !important;
        float: left;
        /*margin-left: 25px;*/
    }
    .dt-mr{
        display: none !important;
    }
    .navbar-ul ul:first-child{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /*margin-left: 25px;*/
        transition:all .5s ease;
    }
}
.dashTitle{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 130px;

}
.margin-right-10-px{
    margin-right: 10px !important;
}
/*.dashTitle:hover{*/
/*    max-width: fit-content;*/
/*}*/
.dashWidth > .dropdown-menu{
    width: max-content;
}
.di-display-inline{
    display: inline;
}

.showInTopMenu{
    display: flex;
    align-items:center;
    justify-content:flex-start;
    margin-top:10px;
    margin-left:2px;
}
.titleInTopMenu{
    margin-left: 20px;
}
.listItem:link {
    padding: 6px 23px;
    margin: 0;
    background-color: #FFF;
    z-index: 9999;
}    /* unvisited link     dark blue:2e46ff  lite blue:2eccff */

.listItem:visited {} /* visited link */
.listItem:hover {color: #148DCE !important; font-weight:normal;}   /* mouse over link */
.listItem:active {color: #148DCE !important;}  /* selected link */

.recordItem:link {color: #767676 !important;}    /* unvisited link */
.recordItem:visited {color:#A9A9A9 !important;} /* visited link */
.recordItem:hover {color: #4c76ff !important; text-decoration: underline;}   /* mouse over link */
.recordItem:active {color: #2eccff !important;}  /* selected link */

.mainAnchorHeading:link {color: #A9A9A9 !important;}    /* unvisited link */
.mainAnchorHeading:visited {color:#A9A9A9 !important;} /* visited link */
.mainAnchorHeading:hover {color: #4c76ff !important;}   /* mouse over link */
.mainAnchorHeading:active {color: #4c76ff !important;}  /* selected link */

.recordHeading:link {color: #767676 !important;}    /* unvisited link */
.recordHeading:visited {color:#A9A9A9 !important;} /* visited link */
.recordHeading:hover {color: #4c76ff !important;}   /* mouse over link */
.recordHeading:active {color: #767676 !important;}  /* selected link */

.umItem:link {color: #767676 !important;}    /* unvisited link     dark blue:2e46ff  lite blue:2eccff */
.umItem:visited {color:#A9A9A9 !important;} /* visited link */
.umItem:hover {color: #4c76ff !important; }   /* mouse over link */
.umItem:active {color: #2eccff !important;}  /* selected link */

.brandImage {
    position: absolute;
    left: 5px;
    top:5px;
    height:60px;
    z-index: 200;
}

/* end id styles */

.menu-link {
    text-decoration: none;
    color: #000000;
    margin: 3px;
    white-space: nowrap;
    font-size: 9pt;
}

.selected-menu-link {
    font-weight: bold;
    color: #007ab6;
}

.height-20 {
}

.margin-left-150 {
    margin-left:150px;
}

.margin-left-160 {
    margin-left: 160px;
}

.top-9 {
    top: 2px !important;
}

.pad-top-2 {
    padding-top: 2px;
}

/* Date-Time Picker */

.date-time-picker-box .input-field {
    box-sizing: border-box;
}
.date-time-picker-box .calendar {
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    cursor: pointer;
}
.date-time-picker-font .calendar {
    font-size: 14px;
}

/* Ventiv Appendable Grid */

.ventiv-grid-box{
    display: block;
}

.ventiv-grid-box table {
    width: 100%;
    margin-bottom: 1em;
}

.ventiv-grid-box td,
.ventiv-grid-box th {
    border: 1px solid #ababab;
    padding: 0.3em;
}

.ventiv-grid-box .form {
    margin-bottom: 1em;
}

.ventiv-grid-bkg th {
    background: #e8e8e8;
}

.ventiv-grid-bkg tr {
    background: #fff;
}

.ventiv-grid-bkg tr:hover {
    background: #e8e8e8;
}

.ventiv-grid-bkg tr.selected {
    background: #d1d1d1;
}

.ventiv-grid-font {
    text-align: left; /* TODO: Temporarily needed because text-align is being used to center containers */
}

.ventiv-grid-font td,
.ventiv-grid-font th {
    font-family: var(--roboto);
    font-size: 13px;
}

.ventiv-grid-font th {
    font-weight: normal;
    text-align: left;
}

/* Section breaks */

.section-break-box {
    border: none;
    border-top: 0.1em solid #ccc;
    margin-top: 2em;
    margin-bottom: 2em;
}
/* Header Panel */

.header-panel-bkg{
    background: #ffffff;
}

.header-panel-box{
    height: 100px;
    padding-top: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-bottom: 1em;
}

.header-panel-container-box{
    border: 0.2em solid #ccc;
    height: 100%;
    overflow: auto;
}

.header-insertion-point {
    height: 25px;
}

.header-insertion-point-selected,
.header-insertion-point:hover {
    background: url('/Alpha/static/custom/layoutDesigner/images/insertion.png');
    background-repeat: no-repeat;
    background-position: 5px center;
    background-size: 15px 15px;
    background-color: #efefef;
}

/* text field styling */

input.alpha-text-xsmall {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

/* Tooltip */
.ventivTooltipParent {
    cursor: pointer;
    position: relative;
}

.ventivTooltip {
    box-sizing: content-box;
    background-color: #000000;
    background-color: rgba(51, 51, 51, 0.9);
    color: #ffffff;
    font-size: 14px;
    padding: 8px;
    width: auto;
    max-width: 250px;
    z-index: 9999;
    position: absolute;
    bottom: 100%;
    left: 10px;
}

/* jQuery Block UI Override */
.blockMsg{
    font-family: var(--roboto);
}

.blockMsg h1{
    font-size: 25px;
}

.select2-results .select2-disabled {
    color: #ccc;
    background: none;
}

.select2-results .select2-disabled .select2-result-label {
    cursor: not-allowed;
}

/****************** New styles for Prospect demo ******************/

/* Page wrapper */

.alpha-page-footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 28px;
    padding: 0 1em;
    color: #fff;
    font-size: 12px;
    line-height: 32px;
    font-family: var(--roboto);
}
.workbench-dashboard-container {
    margin: 5px;
    padding: 5px 5px 47px 5px; /* bottom is footer height + 7px */
}

/* Action buttons */

.alpha-button-reset {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
}

.alpha-button-disabled {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    cursor: not-allowed;
}


.alpha-action-button {
    display: inline-block;
    margin-right: 2em;
    color: #0076b9;
    font-size: 12px;
    line-height: 2em;
    cursor: pointer;
    white-space: nowrap;
    font-family: var(--roboto);
}
.toolbar-record-spacing .alpha-action-button {
    font-size: 12px;
}
.alpha-action-button:active {
    outline: none;
}
.alpha-action-button:focus {
    outline: none;
}
.alpha-action-button:disabled {
    color: #b9b9b9;
}
.alpha-action-button:last-child {
    margin-right: 0;
}
.alpha-action-button .ventiv-icon {
    display: inline-block;
    margin-right: 0.1em;
    font-size: 1.8em;
    vertical-align: middle;
}

.alpha-action-button-reverse .ventiv-icon {
    margin-right: 0;
    margin-left: 0.1em;
}
.alpha-action-button-light {
    color: #ffffff;
}
.alpha-action-button-light:hover {
    color: #c4e1ef;
}
.alpha-action-button-light:disabled {
    color: #b9b9b9;
}
.alpha-action-button-white {
    color: #fff;
}
.alpha-action-button-white:disabled {
    color: #b9b9b9;
}
/* Icon buttons */
.alpha-icon-button {
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    color: #337ab7;
    min-width: 30px;
    line-height: 28px;
    height: 30px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
}
.alpha-icon-button:active,
.alpha-icon-button:focus {
    outline: none;
}
.alpha-icon-button:link,
.alpha-icon-button:visited,
.alpha-icon-button:hover,
.alpha-icon-button:active,
.alpha-icon-button:focus {
    color: #337ab7;
    text-decoration: none;
}
.alpha-icon-button[disabled],
.alpha-icon-button.disabled {
    color: #ccc;
    cursor: default;
}

/* Move buttons */

.alpha-move-button {
    display: block;
    width: 0;
    height: 0;
    overflow: hidden;
    margin: 0 auto 12px auto;
    border: 12px solid transparent;
    cursor: pointer;
}
.alpha-move-button:last-child {
    margin-bottom: 0;
}
.alpha-move-button-right {
    border-left-color: #0076b9;
    border-right: none;
}
.alpha-move-button-left {
    border-right-color: #0076b9;
    border-left: none;
}
.alpha-move-button-right:disabled {
    border-left-color: #b9b9b9;
}
.alpha-move-button-left:disabled {
    border-right-color: #b9b9b9;
}

.alpha-more-button {
    background-color: #148DCE;
    color: white;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

/* Toggle switches */

.alpha-toggle-switch {
    color: #ffffff;
    display: block;
    position: relative;
    line-height: 1;
    padding: 0.5em 1em 0.5em 2.7em;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 2em;
    box-shadow: inset 0 0.1em 0.3em rgba(0, 0, 0, 0.2);
    background: #999;
    font-size: 0.8em;
    text-align: right;
    white-space: nowrap;
    font-family: var(--roboto);
}
.alpha-toggle-switch .handle {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 1.7em;
    height: 1.7em;
    border-radius: 50%;
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.2);
    background: #ccc;
}
.alpha-toggle-switch .label-on {
    display: none;
}
.alpha-toggle-switch.on {
    background: #148DCE;
    text-align: left;
    padding-right: 2.7em;
    padding-left: 1em;
}
.alpha-toggle-switch.on .handle {
    right: 1px;
    left: auto;
    background: #ace8f9;
}
.alpha-toggle-switch.on .label-on {
    display: inline !important;
}
.alpha-toggle-switch.on .label-off {
    display: none;
}
.alpha-toggle-switch:active {
    background: rgba(0, 0, 0, 0.2);
}

/* Scrolling panels */

.alpha-scrolling-panel {
    margin-bottom: 15px; /* Overrides the Bootstrap default */
}
.alpha-scrolling-panel-body {
    height: calc(100vh - 132px);
    overflow: auto;
}
.alpha-message-bar + .alpha-workbench-content .alpha-scrolling-panel-body {
    height: calc(100vh - 172px);
}
/* Panel headings */

.panel-heading .info-icon {
    cursor: pointer;
    color: #ffffff;
    font-size: 1em;
}
.alpha-panel-sub-heading {
    margin: 0;
    padding: 14px 17px;
    border-bottom: 1px solid #ddd;
    color: #0076b9;
    font-size: 12px;
    font-weight: bold;
}
.alpha-panel-sub-heading .help-icon {
    font-size: 1.4em;
}

.panel .ventiv-icon,
.alpha-scrolling-panel .ventiv-icon {
    outline: none;
}

.icon-ic_stack_doc {
    color: #00548B;
    font-size: 15px;
    margin: 4px;
}

/* Workbench header and footer */

.alpha-workbench-header {
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    border-top: 2px solid #fff;
    background-color: #333333;
    padding: 10px 32px;
    font-family: var(--roboto);
}
.alpha-workbench-content {
    padding-top: 65px;
    font-family: var(--roboto);

}
.alpha-message-bar + .alpha-workbench-content {
    padding-top: 105px;
}

.alpha-workbench-header-toolbar {
    line-height: 1em;
    padding-top: 3px;
    font-family: var(--roboto);
}
.alpha-workbench-header-sub-title {
    font-size: 12px;
    color: #fff;
}
.alpha-workbench-header-title{
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
}

.alpha-workbench-header-dropdown {
    z-index: 501;
    position: fixed;
    top: 15px;
    left: 32px;
    font-size: 14px;
    font-weight: bold;
}

.alpha-workbench-header-dropdown button {
    color: #fff;
    font-weight: bold;
    outline: none;
}

.alpha-workbench-header-dropdown li {
    font-size: 12px;
    font-weight: normal;
    color: #333;
}

.alpha-workbench-header-dropdown li a:hover,
.alpha-workbench-header-dropdown li a:active {
    background-color: #f7f7f7;
}
.alpha-workbench-header-dropdown li.selected a,
.alpha-workbench-header-dropdown li.selected a:hover,
.alpha-workbench-header-dropdown li.selected a:active {
    background-color: #c4e1ef;
}

.alpha-page-footer a:hover,
.alpha-page-footer a:focus{
  color: #FFFFFF;
}


/* Panel */

.alpha-panel .row,
.alpha-panel .row-fluid{
    padding-top: 20px;
}

/* String list (an extension of Bootstrap List Group) */

.alpha-list-reset {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.alpha-list-reset li {
    margin: 0;
    padding: 0;
}
.alpha-string-list {
    min-height: 48px;
    max-height: 144px;
    overflow: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 13px;
}
.alpha-string-list .alpha-string-list-item {
    cursor: pointer;
    padding: 5px;
}

/* Radio buttons */

.alpha-radio-group label {
    display: inline-block;
    margin-right: 2em;
    font-weight: normal;
}
.alpha-radio-group label:last-child {
    display: inline-block;
    margin-right: 0;
}
.alpha-radio-group input {
    display: inline-block;
    margin-right: 0.3em;
}

/* Checkboxes */

.alpha-checkbox-group label {
    display: inline-block;
    margin-right: 2em;
    font-weight: normal;
}
.alpha-checkbox-group label:last-child {
    display: inline-block;
    margin-right: 0;
}
.alpha-checkbox-group input {
    display: inline-block;
    margin-right: 0.3em;
}

/* Property lists */

.alpha-dl-reset {
    margin: 0;
    padding: 0;
}
.alpha-dl-reset dt,
.alpha-dl-reset dd {
    margin: 0;
    padding: 0;
}
.alpha-property-list {
    font-family: var(--roboto);
    font-size: 13px;
}
.alpha-property-list dt {
    margin-bottom: 5px;
    color: #333;
    font-weight: normal;
}
.alpha-property-list dd {
    margin-bottom: 15px;
    color: #000;
}
.alpha-property-list dd:last-child {
    margin-bottom: 0;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Standard modals */

.modal-default * {
    box-sizing: border-box;
}
.modal-default *:before,
.modal-default *:after {
    box-sizing: border-box;
}
.modal-default .modal-content {
    border-radius: 0;
}
.modal-default .modal-header {
    border-bottom: none;
}
.modal-default .modal-body {
    padding-bottom: 0;
}
.modal-default .modal-body>.row:last-child {
    margin-bottom: 0;
}
.modal-default .modal-footer {
    border-top: none;
}
.modal-default .modal-toolbar-bgd{
    background-color: #fff;
}
.modal-default .modal-toolbar-box{
    height: 80px;
    padding: 20px;
}
.modal-default .modal-title-box{
    padding: 10px 20px;
    border-bottom: 1px solid rgb(229, 229, 229);
    min-height: 16px;
}
.modal-default .modal-header {
    padding: 10px 15px;
    background-color: #0076b9;
    color: #fff;
    font-weight: bold;
}
.modal-default .modal-title-bgd {
    background-color: #0076b9;
}
.modal-default .modal-content-pos {
    position: absolute;
    top: 42px;
    bottom: 60px;
    left: 0;
    right: 0;
}
.modal-default .modal-content-font {
    text-align: left;
}
.modal-default .modal-title,
.modal-default .modal-title-font{
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}
.modal-default .close{
    color:#fff;
    opacity: 1;
    font-size: 24px;
    font-weight: normal;
    margin-top: -7px;
}
.modal-default .modal-bgd{
    background-color: #fff;
}
.modal-default .modal-body{
    font-family: var(--roboto);
    font-size: 12px;
    font-weight: normal;
    color: #333;
}
.modal-default .modal-lg .modal-box {
    height: 350px;
}
.modal-default textarea{
    resize: none;
}
.modal-default .row{
    margin-bottom: 20px;
}
.modal-default .input-field-lbl{
    font-size: 12px;
    font-weight: normal;
}

/* Button-Triggered Dropdowns */

.alpha-button-dropdown .dropdown-menu {
    font-family: var(--roboto);
    border-radius: 0;
    margin-top: 5px;
    padding: 0;
    left: auto;
    right: 0;
    border: 1px solid #ccc;
}
.alpha-button-dropdown .dropdown-menu.loading-items {
    padding-bottom: 40px;
    background: #fff url('/Alpha/static/custom/common/images/spinner_20px.gif') no-repeat center bottom 10px;
}
.alpha-workbench-header .alpha-button-dropdown .dropdown-menu {
    right: 1em; /* Buttons in these headers have larger margins */
}
.alpha-button-dropdown .dropdown-menu:before {
    position: absolute;
    top: -8px;
    right: 9px;
    display: block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}
.alpha-button-dropdown .dropdown-menu:after {
    position: absolute;
    top: -9px;
    right: 10px;
    display: block;
    border-right: 6px solid transparent;
    border-bottom: 9px solid #fff;
    border-left: 6px solid transparent;
    content: '';
}
.alpha-button-dropdown .dropdown-menu>li>a {
    color: #333;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
}
.alpha-button-dropdown .dropdown-menu>li>a:hover {
    color: #148DCE;
    background-color: #eee;
}
.alpha-button-dropdown .dropdown-menu>li.disabled>a,
.alpha-button-dropdown .dropdown-menu>li.disabled>a:hover {
    color: #ccc;
    background-color: transparent;
}
.alpha-button-dropdown .alpha-button-dropdown-heading {
    margin: 0;
    padding: 10px 20px;
    color: #333;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

/* Dirty State Modals */
.modal-dirty-state .modal-dialog {
    width: 400px;
}

/* Banner Modals */
.alpha-common-banner-modal{
    color: #fff;
    margin-top: -14px;
    margin-bottom: 14px;
}
.alpha-common-banner-modal-message{
    font-size: 14px;
    margin: 15px;
    font-family: var(--roboto);
}
.alpha-common-banner-modal-overlay{
    z-index: 400;
    position: absolute;
    top: 170px;
    bottom: 0;
    left: 20px;
    right: 20px;
    background: rgba(33, 33, 33, 0.3);
}
.alpha-common-banner-modal .btn{
    height: 30px;
    width: 70px;
}
.alpha-common-banner-modal-buttons{
    margin: 10px;
}
.alpha-common-banner-modal + .row .alpha-scrolling-panel-body{
    height: calc(100vh - 250px);
}
.alpha-common-banner-modal.success-bgd {
    background-color: #00A553;
}
.alpha-common-banner-modal.warning-bgd{
    background-color: #eb9b37;
    color: #000;
}
.alpha-common-banner-modal.danger-bgd{
    background-color: #970000;
    color: #FFF;
}
/* Instruction modals */

.modal-instruction .modal-title-bgd{
    background-color: #000;
}
.modal-instruction .modal-title-font{
    color: #ea642d;
    font-size:24px;
    font-weight: bold;
    text-align: center;
}
.modal-instruction .modal-title-box{
    border-bottom: none;
}
.modal-instruction .modal-box{
    height: 532px;
}
.modal-instruction .modal-bgd{
    background-color: #000;
}
.modal-instruction .modal-font{
    text-align: left;
}
.modal-instruction .modal-toolbar-box .btn{
    background-color: #000;
    color: #43b748;
    font-size:18px;
    border: 3px solid #fff;
    height: auto;
    width: auto;
}
.modal-instruction .modal-content-font{
    font-size:16px;
    color: #fff;
    line-height: 1.5em;
}
.modal-instruction .modal-content-box{
    padding: 15px 40px 60px 40px;
}
.modal-instruction .modal-toolbar-bgd{
    background-color: #000;
}
.modal-instruction .modal-toolbar-box{
    height: 70px;
}
.modal-instruction .modal-toolbar-font{
    text-align: center;
}
.modal-instruction .ventiv-icon{
    font-size: 3em;
}
.modal-instruction .icon-ic_hand{
    color: #f8c78b;
}

/* Alert modals */

.modal-alert .modal-content-box {
    padding: 50px 20px 20px 20px;
}
.modal-alert .modal-content-font {
    text-align: center;
}

/* Main Navigation */

.user-profile {
    width: 30px;
    height: 30px;
    position: fixed;
    margin: -5px -18px 0 -18px !important;
    background: url('/Alpha/static/custom/common/images/ic_user.svg');
}
.user-image {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    min-width: 0;
}
.user-name {
    display: inline-block;
}

.vcenter{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#anchorUser5 .caret {
    margin-left: 0;
}
.selected-menu-link {
    font-weight: bold !important;
    color: #007ab6 !important;
}
.dropdown-submenu {
    position: relative;
}
.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
    max-height: 265px;
    overflow-y: auto;
}
.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}
.dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
    border-left-color: #fff;
}
.dropdown-submenu.pull-left {
    float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}
.alpha-main-nav {
    border: none;
    border-radius: 0;
    color: #FFFFFF;
    text-shadow: none;
    background-color: #00548B;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    font-family: var(--roboto);
    height: 85px;
    margin-bottom: 0;
}
.dropdown-menu {
    margin-top: -5px;
}
.alpha-main-nav .nav {
    margin-right: 40px;
}
.alpha-main-nav .nav > li > a:link,
.alpha-main-nav .nav > li > a:visited,
.alpha-main-nav .nav > li > a:hover,
.alpha-main-nav .nav > li > a a:active {
    color: #fff !important; /* to override !important global style */
}
.alpha-main-nav .navbar-nav > .open > a,
.alpha-main-nav .navbar-nav > .open > a:hover,
.alpha-main-nav .navbar-nav > .open > a:focus {
    background-color: transparent !important;
}
.alpha-main-nav .nav > li > .dropdown-menu:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: 9px;
}
.alpha-main-nav .nav > li > .dropdown-menu:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    position: absolute;
    top: -6px;
    left: 10px;
}
.alpha-main-nav .nav li.dropdown > a:hover .caret, .alpha-main-nav .nav li.dropdown > a:focus .caret {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
}
.alpha-main-nav .nav li.dropdown.open > .dropdown-toggle, .alpha-main-nav .nav li.dropdown.active > .dropdown-toggle, .alpha-main-nav .nav li.dropdown.open.active > .dropdown-toggle {
    background-color: #e5e5e5;
    color: #555555;
}
.alpha-main-nav .nav li.dropdown > .dropdown-toggle .caret {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
}
.alpha-main-nav .nav li.dropdown.open > .dropdown-toggle .caret, .alpha-main-nav .nav li.dropdown.active > .dropdown-toggle .caret, .alpha-main-nav .nav li.dropdown.open.active > .dropdown-toggle .caret {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
}
.alpha-main-nav .pull-right > li > .dropdown-menu, .alpha-main-nav .nav > li > .dropdown-menu.pull-right {
    left: auto;
    right: 0;
}
.alpha-main-nav .pull-right > li > .dropdown-menu:before, .alpha-main-nav .nav > li > .dropdown-menu.pull-right:before {
    left: auto;
    right: 12px;
}
.alpha-main-nav .pull-right > li > .dropdown-menu:after, .alpha-main-nav .nav > li > .dropdown-menu.pull-right:after {
    left: auto;
    right: 12px;
}
.alpha-main-nav .pull-right > li > .dropdown-menu .dropdown-menu, .alpha-main-nav .nav > li > .dropdown-menu.pull-right .dropdown-menu {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: -1px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

/* Main tabs */

.alpha-main-tabs {
    margin: -30px 15px 0 15px;
    font-family: var(--roboto);
}
.alpha-main-tabs .alpha-main-tab {
    position: relative;
    float: left;
    padding-left: 2px;
    min-width: 50px;
    max-width: 175px;
    height: 30px;
    opacity: 0.9;
    color: #fff;
    font-family: var(--roboto);
}
.alpha-main-tabs .alpha-main-tab:first-child {
    padding-left: 0;
}
.alpha-main-tabs .alpha-main-tab-active {
    opacity: 1;
    color: #333;
}
.alpha-main-tabs .alpha-main-tab:hover {
    opacity: 1;
}
.alpha-main-tab-icon {
    vertical-align: middle;
    font-size: 16px;
    line-height: 30px;
}
.alpha-main-tab-title {
    line-height: 30px;
    font-size: 12px;
    font-weight: 600;
    font-family: var(--roboto);
}

.alpha-main-tab-dirty-state-icon {
    height: 8px;
    width: 8px;
    position: absolute;
    padding-left: 1.4px;
    padding-top: .8px;
    left: 24px;
    bottom: 6px;
    line-height: 7px;
    color: #fff;
    background: #b60a31;
    border-radius: 5px;
    font-size: 5px;
}


.alpha-main-tab-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 1em;
    background: #80aac6;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    font-size: 12px;
}
.alpha-main-tab-active .alpha-main-tab-link {
    background: #fff;
}
.alpha-main-tab-link:link,
.alpha-main-tab-link:visited,
.alpha-main-tab-link:hover,
.alpha-main-tab-link:active {
    color: inherit;
}
.alpha-main-tab-close {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
    padding: 0.2em;
    line-height: 0.6em;
    font-weight: bold;
}
.alpha-main-tab:hover .alpha-main-tab-close {
    display: inline-block;
}
.alpha-main-tab-close:hover,
.alpha-main-tab.active .alpha-main-tab-close:hover {
    color: #a94442;
}
.alpha-main-tab-content {
    position: absolute;
    top: 85px;
    bottom: 28px;
    width: 100%;
    overflow: auto;
    background: #fff;
    font-size: 13px; /* mimics JQWidget style from the old tabs; a lot of content is based on this */
}
.alpha-main-tab-frame {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    min-width: 1024px;
    width: 100%;
    height: 100%;
    border: none;
}
.alpha-main-tab.tab-error .alpha-main-tab-title {
    color:#c00e25;
    font-weight: bold;
}

/* Table */
/* This includes a mix of Bootstrap table overrides and the newer div-based tables  */

.alpha-table-title {
    margin: 0;
    padding: 0.5em;
    background: #148DCE;
    color: #fff;
    font-size: 15px;
    font-weight: normal;
}
.alpha-table-container {
    width: 100%;
    position: relative;
    border: 1px solid #ddd;
    clear:both;
}
.alpha-smart-table-header-div.bordered {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
}
.alpha-table{
    color: #333;
    font-size: 12px;
    overflow-x: auto;
}
.alpha-table-heading,
.alpha-table .disabled{
    background-color: #eee;
}
.alpha-table-heading,
.alpha-table-cell{
    padding: 4px;
    position: relative;
}
.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* You can set a specific width if needed */
    display: inline-block;
    vertical-align: middle;
}
@media (min-width: 768px) {
    .alpha-field-history .modal-dialog {
        width: 750px !important;
        max-width:1200px;
    }
}
.popoverBox {
    position: fixed;
    transform: translateY(-54%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 4;
    height: auto;
    max-height: 185px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.popoverBoxContent {
    flex-grow: 1;
    overflow-y: auto;
    white-space: normal;
}
.popoverBoxContent span {
    display: block;
    overflow-wrap: break-word;
}
.field_history_table .alpha-table-heading {
    padding-right: 15px;
    padding-left: 15px;
}
.alpha-table-heading,
.alpha-table-cell {
    display: inline-block;
    height: 100%;
    border-right: 1px solid #ddd;
    line-height: 20px;
    font-size: 12px;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--roboto);
}

.alpha-table-heading {
    font-weight: bold;
    font-family: var(--roboto);
    border-bottom: 2px solid #ddd;
    text-align: center;
    padding-right: 24px; /* for lock icon */
    padding-left: 24px; /* for lock icon */
}
.editable-grid-header:first-child, .editable-grid-header:nth-child(2) {
    font-weight: bold;
    border-bottom: 2px solid #ddd;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
}
.alpha-table-heading.highlighted{
    background-color: #428BCA;
    color: #FFF;
    border: 2px solid #428BCA;
}
.alpha-table-heading.highlighted .alpha-smart-table-unlock-column{
    color: inherit;
}
.alpha-table>.alpha-table-body>.alpha-table-row>.alpha-table-cell.highlighted{
    background-color: #ebf7fd;
}
.alpha-table>.alpha-table-body>.alpha-table-row.selected-row>.alpha-table-cell{
    background-color: #428BCA;
    color: #FFF;
    border: 2px solid #428BCA;
}
.alpha-table>.alpha-table-body>.alpha-table-row.map-row>.alpha-table-cell{
    background-color: #2DC406;
    color: #FFF;
}
.alpha-table>.alpha-table-body>.alpha-table-row.map-inherit-row>.alpha-table-cell{
    background-color: #F5F113;
}
.alpha-table>.alpha-table-body>.alpha-table-row.map-object-row>.alpha-table-cell{
    background-color: #F71112;
    color: #FFF;
}
.alpha-table-cell {
    border-bottom: 1px solid #ddd;
}
.alpha-table-heading.sortable {
    cursor: pointer;
}
.alpha-table-heading:last-child,
.alpha-table-cell:last-child {
    border-right: none;
    border-right: 1px solid #ddd;
}
.alpha-table-heading:hover span.glyphicon-move {
    opacity: 1;
    padding-top: 3px;
}
.alpha-table-row {
    height: 28px;
    white-space: nowrap;
    font-size: 0; /* to eliminate spacing between cells */
}
.alpha-table-user-filter-row.alpha-table-row {
    height: 42px;
    background: #edf2f6;
    width: fit-content;
}

.alpha-table-user-filter-row .ast-user-filter-field .select2-drop {
    width: unset;
}


.alpha-table-user-filter-row div.ast-user-filter-field>input[type="search"] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-appearance: searchfield;
    display: inline-block;
    width: calc(100% - 25px);
}
.alpha-table-user-filter-row .lookup-list-margin{
    padding-left: 20px;
}

.alpha-table-user-filter-row input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}

.alpha-table-user-filter-row input.range-control {
    width: calc(100% - 25px);
}
.alpha-table-row.record-view{
    cursor: pointer;
}
.alpha-table-row.record-view .not-viewable{
    cursor: not-allowed;
}
.alpha-table-body:last-child:not(.multiline-table-body) .alpha-table-row:last-child:not(.alpha-table-user-filter-row) > .alpha-table-cell,
.alpha-table-footer .alpha-table-row:last-child > .alpha-table-cell {
    border-bottom: none;
}
.alpha-table .selected .alpha-table-cell:not(.alpha-i18n-input),
.alpha-table-body .st-selected .alpha-table-cell:not(.alpha-i18n-input),
.alpha-table-body .alpha-table-row:hover .alpha-table-cell:not(.alpha-i18n-input) {
    background-color: #ebf7fd;
}
.alpha-table .st-sort-ascent .heading:before,
.alpha-table .st-sort-descent .heading:before {
    display: inline-block;
    margin-right: 0.5em;
}
.alpha-table .st-sort-ascent .heading:before {
    content: '\25B2';
}
.alpha-table .st-sort-descent .heading:before {
    content: '\25BC';
}
.alpha-table-header {
    background-color: #148DCE;
    font-weight: normal;
    font-size: 14px;
    color: #FFF;
    padding: 5px 15px 5px;
    min-height: 30px;
    font-family: var(--roboto);
}

/* Smart Table directives */


.alpha-smart-table-header {
    margin: 5px 0;
    white-space: nowrap;
    text-align: right;
    overflow: auto;
    overflow-y: hidden;
}

.alpha-smart-table-header-unset-scroll {
    overflow: unset !important;
}

.alpha-smart-table-header .alpha-smart-table-search {
    width: 150px;
}
.alpha-smart-table-header .alpha-smart-table-search,
.alpha-smart-table-header .alpha-icon-button {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    float: initial;
}
.alpha-smart-table-header .alpha-smart-table-filter-icon{
    display: inline;
}

/****** grid search box ********/

.alpha-smart-table-resize {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    cursor: ew-resize;
}
.alpha-smart-table-footer {
    font-size: 12px;
    font-family: var(--roboto);
}
.alpha-smart-table-footer>nav {
    height: 50px;
}
.alpha-smart-table-summary,
.alpha-smart-table-footer .pagination{
    margin: 10px 0;
}
.alpha-smart-table-summary select,
.alpha-smart-table-footer .pagination>li>a, .pagination>li>span{
    height: 30px;
    font-size: 10px;
    width: 35.9px;
    text-align: center;
}
.alpha-smart-table-footer .pagination>li>a, .pagination>li>span {
    padding: 0 12px;
    line-height: 28px;
}
.alpha-smart-table-summary label {
    font-weight: normal;
    font-size: 10px;
}
.alpha-table-cell.locked:not(.alpha-table-editable-cell) {
    background-color: #f9f9f9;
}

.alpha-table-cell.locked.alpha-table-editable-cell {
    background-color: #fff;
}

.alpha-table-heading.locked,
.alpha-table-cell.locked {
    position: absolute;
    height: 28px;
    z-index: 1;
}
.alpha-smart-table-lock-column,
.alpha-smart-table-unlock-column {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    line-height: 28px;
}
.alpha-smart-table-unlock-column {
    color: #1c8ecb;
}
.alpha-smart-table-over {
    background-color: #EBF7FF;
    color: #366CA1;
    width: auto;
}
.sortable-handle{
    cursor: pointer;
    opacity: 0;
}
.sortable-handle:hover{
    opacity: 1;
}
.alpha-smart-table-editable form{
    display: inline;
}
.alpha-smart-table-editable .editing .edit-btn{
    color: #000;
    opacity: 0;
}
.alpha-smart-table-editable .editing:hover .edit-btn{
    opacity: 1;
}
.alpha-smart-table-editable .glyphicon{
    font-size: 12px;
}
.alpha-smart-table-editable input{
    display : inline-block;
    height: 100%;
    line-height: 20px;
    font-size: 12px;
    width: 70%
}
.alpha-smart-table-chart-button{
    padding: 0 5px;
}
.alpha-smart-table-download-button .glyphicon-export {
    /* This is a duplication of the .glyphicon CSS from bootstrap.min.css minus a display: inline-block;
        property which interferes with IE11's ability to open a popover. This can be removed and the
        standard glyphicon class can be added back when we no longer support IE11. */
    position: relative;
    top: 1px;
    font-family: 'Glyphicons Halflings' !important;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.modal-chart-column-selection .modal-default{
    width: auto;
}
.modal-default .modal-lg .alpha-smart-table-column-selection.modal-box{
    height: auto;
}
.alpha-table-switch button{
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.alpha-smart-table-column-selection{
    position: relative;
    z-index: 1;
    background: transparent;
}
.c3 + .alpha-smart-table-column-selection{
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
}
.c3.chart-translucent{
    margin-top: 0;
}
.alpha-smart-table-chart .c3-legend-item {
    cursor: default !important;
}
.chart-translucent{
    opacity: 0.1;
    z-index: 0;
}
.chart-legend-title{
    font-size: 18px;
    font-weight: bold;
    stroke: #000;
    fill: #000;
    stroke-width: 0;
    font-family: var(--roboto);
}
.alpha-smart-table-column-selection .body{
    display: flex;
}
.alpha-smart-table-column-selection .body.pie-chart-selection{
    display: block;
}
.alpha-smart-table-column-selection .footer{
    padding: 15px
}
.alpha-smart-table-column-selection .header{
    font-weight: normal;
    padding: 5px;
    text-align: left;
    border-bottom: 1px solid #DDD;
}
.alpha-smart-table-column-selection .header{
    font-weight: normal;
    font-size: 14px;
}
.alpha-smart-table-column-selection .column-container{
    flex-grow: 1;
    text-align: left;
    font-size: 14px;
    border-bottom: 1px solid #DDD;
}
.alpha-smart-table-column-selection .column{
    padding: 5px 0;
}
.alpha-smart-table-column-selection .column-container + .column-container{
    border-left: 1px solid #DDD;
}
.alpha-smart-table-column-selection .center-block{
    text-align: center;
}
.alpha-smart-table-column-selection span.disabled{
    color: #ccc;
}
.alpha-smart-table-menu label {
    color: #333333;
}
.alpha-smart-table-menu .menu-item {
    color: inherit;
}
.alpha-smart-table-menu .selected-menu-item{
    color:#337ab7;
}

.popover-options-list {
    margin: 0;
}
.popover-options-list a {
    white-space: nowrap;
}
.popover-options-list a:hover {
    color: #148DCE;
}
.popover-options-list li {
    margin: 0.2em 0;
}

.chart-container{
    position: relative;
    border-bottom: 1px solid #DDD;
}
.chart-container .chart-area-buttons{
    position: relative;
    top: 0;
    left:0;
    right:0;
    margin: 15px;
}
.chart-container .chart-selector{
    max-width: 500px;
    width: auto;
}
.chart-container .chart-header .alpha-icon-button,
.chart-container .chart-header .chart-selector {
    margin-right: 10px;
}
.chart-container .center-block{
    border-bottom: 1px solid #DDD;
    padding: 5px;
}
.chart-container .chart-header .select2-container .select2-choice > .select2-chosen{
    color: #0076b9;
}
.chart-container .chart-header .select2-container-disabled .select2-choice > .select2-chosen{
    color: #ccc;
}
.chart-container .chart-area-buttons button{
    color: #0076b9;
}
.chart-container .chart-header{
    border-bottom: 1px solid #DDD;
    font-size: 16px;
    padding: 5px 0;
    text-align: left;
}
.chart-header-title{
    vertical-align: sub;
}
.chart-container .chart-header .alpha-button-reset .glyphicon{
    transform: rotate(180deg);
}
.chart-container .chart-header .close-chart {
    color: #0076b9;
    font-size: 24px;
    font-weight: bold;
}
.chart-container .chart-header .close-chart[disabled] {
    color: #ccc;
}
/*scrolling for the alpha smart table*/
.scroll-height-header {
    width: 100%;
    position: static;
    overflow-x: hidden;
    overflow-y: scroll;
    border-left:1px solid #ddd;
    border-right:1px solid #ddd;
}
.scroll-height-body {
    bottom: 80px;
    width: 100%;
    overflow: auto;
    overflow-y: scroll;
    border: 1px solid #ddd;
}
.scroll-height-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}
/* Start Page Grid */

.alpha-start-grid .alpha-action-button {
    line-height: 38px;
    margin-left: 6px;
}
.alpha-start-grid .alpha-table-container {
    border-right: none;
    border-bottom: none;
    border-left: none;
}
.alpha-start-grid .alpha-table {
    margin-bottom: 0;
}

/* Connected Lists */

.alpha-connected-list-panel {
    height: 250px;
    font-size: 14px;
    overflow: auto;
}
.alpha-connected-list li {
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
}
.alpha-connected-list li.selected {
    background-color: #E6F8FF;
}
.alpha-connected-list-button {
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 2px;
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #337ab7;
}
.alpha-connected-list-button:hover {
    color: #286090;
}
.alpha-connected-list-button:disabled {
    cursor: not-allowed;
    opacity: .65;
}
.alpha-connected-list-button:disabled:hover {
    color: #337ab7;
}
.alpha-connected-list-button:first-child {
    margin-top: 40px;
}
.alpha-connected-list-button .glyphicon {
    top: 2px;
}
.alpha-connected-list-button .glyphicon-triangle-right {
    margin-left: 0.1em;
}
.alpha-connected-list-button .glyphicon-triangle-left {
    margin-right: 0.1em;
}
.alpha-connected-list-button .glyphicon-forward {
    margin-left: 0.2em;
}
.alpha-connected-list-button .glyphicon-backward {
    margin-right: 0.2em;
}

.primary{
    color: #0076b9;
}

/* Popovers */

.alpha-popover {
    min-width: 250px;
    font-family: var(--roboto);
    font-size: 10px;
    color: #333;
}

.editable-grid-error-popover{
    max-width: unset;
    min-width: 250px;
    font-family: var(--roboto);
    font-size: 12px;
    font-weight: 400;
    color: #333;
}

.alpha-popover .popover-title {
    font-size: 12px;
    font-weight: bold;
}

/* Global search tool */

.alpha-global-search-tool * {
    box-sizing: border-box;
}
.alpha-global-search-tool {
    z-index: 2;
    position: absolute;
    top: 10px;
    right: 10px;
}
.alpha-global-search-tool .search-input {
    display: inline-block;
    width: 300px;
    height: 32px;
    margin: 0;
    padding: 0 12px;
    border: none;
    border-radius: 0;
    line-height: 32px;
    font-size: 14px;
    color: #333;
}
.inner-search.alpha-global-search-tool{
    right: 20px;
    left: 20px;
    top: 20px;
}
.inner-search.alpha-global-search-tool .search-input{
    width: 100%;
}
.alpha-global-search-tool .search-button {
    margin: 0;
    padding: 0 12px;
    line-height: 32px;
    color: #FFF;
}
.alpha-global-search-tool .search-button:hover{
    border: 1px dashed #ccc;
}
.alpha-global-search-tool.inner-search .search-button:hover{
    border: none;
}
.alpha-global-search-tool .search-button .ventiv-icon {
    margin-right: 0;
    font-size: 1.5em;
}
.alpha-global-search-tool .search-facet-group {
    display: inline-block;
    background: #0076b9;
    line-height: 32px;
    color: #fff;
    padding: 0 12px;
    font-size: 14px;
    margin-right: -5px;
}
.alpha-global-search-tool .search-option-container {
    position: absolute;
    z-index: 1000;
    top: 32px;
    left: 0;
    right: 0;
    border: 1px #333 solid;
    background-color: #fff;
    padding: 20px;
    overflow-y: auto;
    max-height: 300px;
}
.alpha-global-search-tool .search-option-container .input-field-lbl {
    margin: 0 0 1em 0;
    color: #333;
}
.alpha-global-search-tool .search-input-container {
    white-space: nowrap;
    overflow: hidden;
    max-width: 0;
}
.alpha-global-search-tool.expanded .search-input-container {
    transition: max-width 0.5s ease;
    max-width: 100%;
}
.alpha-global-search-tool .radio-inline input{
    margin-top: 10px;
}

.inner-search.alpha-global-search-tool.expanded .search-input-container {
    width: calc(100% - 41px);
}

div#menuSearch .rec-type-div   {
    display: none;
}
.alpha-map-marker .file-text2 {
    font-size: 20px;
}
.alpha-map-marker .media-right{
    vertical-align: middle;
}
.alpha-map-marker .media-body{
    width: auto;
    min-width: 150px;
}
.alpha-google-map-toolbar{
    color: #FFFFFF;
    background-color: #428BCA;
    border-radius: 0;
    height: 25px;
    border: none;
    padding: 0 15px 10px;
}
.alpha-google-map-toolbar > span{
    padding-top: 4px;
}
.alpha-google-map-toolbar .ventiv-icon{
    font-size: 18px;
}
.alpha-google-map-toolbar .buttons{
    vertical-align: middle;
}
.alpha-google-map-toolbar button{
    padding-left: 5px;
    padding-right: 5px;
}
.alpha-google-map-toolbar button:last-child{
    padding-left: 5px;
    padding-right: 0;
}
.alpha-google-map-toolbar .icon-ic_expand_out {
    position: relative;
    top: 0;
    left: 0;
}
.alpha-google-map-form button{
    margin: 25px 2px 10px;
    width: 90px;
    height: 42px;
    background-color: #FFF;
    color: #333;
    font-size: 1em;
    border: 1px solid #CCC;
}
.alpha-google-map.maximized {
    position: absolute !important;
    height: 100%;
    width: 100%;
}
.alpha-google-map.collapsed {
    height: 0;
}
.alpha-google-map-form{
    position: absolute;
    margin-top: 50px;
    top: 0;
    left: 0;
    width: 40%;
    height: 100%;
}
.alpha-google-map-form button.primary{
    background-color: #0076b9;
    color: #fff;
}
.alpha-google-map{
    height: 350px;
    width: 100%;
}

.alpha-google-map-info-window{
    font-size: 12px;
    font-family: var(--roboto);
    background-color: #FFF;
}
.alpha-google-map-info-window .header{
    font-weight: bold;
    color: #000;
    margin-bottom: 15px;
}
.alpha-google-map-info-window span{
    color: #428BCA;
}

/* Message Bar */

.alpha-message-bar {
    position: fixed;
    top: 50px;
    right: 0;
    left: 0;
    font-size: 13px;
    padding: 5px 20px;
}
.alpha-message-bar.confirmation {
    background-color: #bd132b;
}
.alpha-message-bar .message {
    line-height: 30px;
    color: #fff;
}

/* User Profile */
.user-profile {
    width: 30px;
    height: 30px;
    position: fixed;
    margin: -6px -18px 0 -18px;
    background: url('/Alpha/static/custom/common/images/ic_user.svg');
}
.user-image {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    min-width: 0;
}

/* Loading indicator */

.blockUI.blockOverlay {
    z-index: 2000;
}
.blockUI.blockMsg {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    background: #fff;
    z-index: 2001;
    font-family: var(--roboto);
}
.blockUI.blockMsg.blockElement {
    display: inline-block;
    position: absolute;
    left: 50% !important; /* Can't be overridden in the options */
    top: 50% !important; /* Can't be overridden in the options */
}
.blockUI.blockMsg h1 {
    margin: 0;
    padding: 15px 20px 15px 75px;
    background: url('/Alpha/static/custom/common/images/spinner_40px.gif') 15px center no-repeat;
    font-size: 26px;
    text-align: center;
    font-style: italic;
    font-weight: normal;
    line-height: 40px;
    color: #000;
    white-space: nowrap;
    font-family: var(--roboto);
}
.page-info-tab-popover {
    width: 250px;
    max-width: 250px !important;
    z-index: 9999 !important;
    font-size: 12px;
    border-radius: 0px;
}
.page-info-tab-popover .popover-title-text{
    border-bottom: 1px solid #ebebeb;
    margin-bottom:5px;
    padding: 5px;
    font-weight: bold;
}
.page-info-tab-popover .popover-content,
.ast-user-filter-popover .popover-content,
.ast-user-filter-between-wrapper .popover-content {
    padding: 0px;
}
.ast-user-filter-popover:not(.ast-user-filter-popover-last).popover>.arrow:after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #fff;
}
.ast-user-filter-popover:not(.ast-user-filter-popover-last).popover>.arrow {
    top: -11px;
    left: 0;
    margin-left: 0px;
    border-top-width: 0;
    border-bottom-color: rgba(0,0,0,.25);
}
.ast-user-filter-popover:not(.ast-user-filter-popover-last).popover {
    margin-top: 10px;
}

.ast-user-filter-popover {
    background-color : rgb(255, 255, 255);
    min-width: unset;
}

.ast-user-filter-popover .arrow {
    display: none;
}

.between-value-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.ast-user-filter-between-wrapper {
    padding: 5px;
    width: 410px;
    width: max-content;
}

.ast-user-filter-min-width {
    min-width: 200px;
}
.ast-user-filter-multiselect-wrapper {
    padding: 5px;
}
.page-info-tab-popover .text-color{
    color: #148DCE;
}
.page-info-tab-popover .label-color{
    color: #262626;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.page-info-tab-popover .container-fluid{
    margin: 5px;
    padding: 0px;
}
.page-info-tab-popover .row {
    padding-bottom:3px;
}

.alpha-autoexpand-textarea-container {
    position: relative;
}

.alpha-autoexpand-textarea, .alpha-autoexpand-textarea-size {
    min-height: 20px;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    border: none;
}

.alpha-autoexpand-textarea {
    height: 100%;
    position: absolute;
    resize:none;
}
.alpha-autoexpand-textarea.readonly {
    background-color: #eeeeee;
}
.alpha-autoexpand-textarea-size {
    visibility: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
.recent-record-list{
    color: #333333;
    width: 500px;
    font-size: 12px;
    overflow: auto;
}
.recent-record-list .recent-record-list-header{
    padding: 5px 0;
    font-size: 12px;
}
.recent-record-list .recent-record-list-header button{
    color: #418bca;
}
.recent-record-list .record-header{
    background: #EFEFEF;
    padding: 5px 0;
}
.recent-record-list .record-detail{
    padding: 2px 0;
}
.recent-record-list .record-detail:hover{
    color: #418bca;
    cursor: pointer;
}
.recent-record-list button{
    font-size: 12px;
}
.recent-record-list dl{
    margin-bottom: 0;
}
.ventiv-icon.icon-ic_error {
    color: #c00e25;
}
.alpha-email-addresses{
    display: block;
    width: 100%;
    min-height: 34px;
    padding: 0;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    margin: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    overflow: auto;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}
.alpha-email-addresses:focus{
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.alpha-email-address{
    margin: 3px 0 3px 3px;
    padding: 6px 7px;
    color: #555;
    background: #fff;
    border: solid 1px #ccc;
    border-radius: 3px;
}
.alpha-email-address .close{
    color: #555;
    font-size: 12px;
    opacity: 1;
    padding-right: 5px;
    float: left;
}
.alpha-email-input-container.ng-invalid .alpha-email-addresses{
    border-color: #a94442;
}
.alpha-email-addresses.read-only,
.alpha-email-addresses.read-only .alpha-email-address {
    border-color: #ccc;
    background-color: #eee;
}
.alpha-email-button{
    height: 34px;
    color: #ccc;
    font-size: 14px;
    position: relative;
}
.alpha-email-button:enabled:hover,
.alpha-email-button:enabled:focus{
    background-color: #418bca;
    color: #FFF;
}
.alpha-email-popover{
    min-width: 600px;
    max-width: 600px;
    /* Needed to correctly position popover relative to widget */
    top: 28px !important;
    left: -562px !important;
}
.alpha-email-popover.popover.bottom > .arrow{
    left: 96.5%;
}
.alpha-email-popover .alpha-table-row {
    cursor: pointer;
}
.alpha-email-popover .alpha-email-selector-footer{
    display: block;
}
.alpha-email-popover input,
.input-group .alpha-email-popover .form-control:not(:first-child):not(:last-child){
    border-radius:4px;
}
.input-group .alpha-email-popover .form-control.alpha-smart-table-search{
    width: auto;
    border-radius:4px;
}
.alpha-email-popover .alpha-smart-table-footer .pagination,
.alpha-email-popover alpha-smart-table-footer .form-group{
    padding: 0;
}
.alpha-email-popover .alpha-smart-table-summary select{
    display: inline-block;
    width: auto;
    float: none;
}
.alpha-email-popover .alpha-table{
    overflow: auto;
}
.has-error .form-control.alpha-smart-table-search,
.has-error .alpha-search-tree .form-control,
.has-error .form-control.alpha-smart-table-search:focus{
    border: 1px solid #ccc;
}

.has-error .alpha-i18n-popover .form-control,
.has-error .alpha-i18n-popover .form-control:focus,
.has-error .alpha-search-tree .form-control:focus{
    box-shadow: none;
}

.alpha-i18n-container {
    position: relative;
}
.alpha-i18n-container .default-input {
    padding-right: 35px;
}
.alpha-i18n-btn-container {
    position: absolute;
    right: 2px;
    top: 3px;
    line-height: 1;
}
.alpha-i18n-popover{
    min-width: 375px;
    max-width: 375px;
}
.alpha-i18n-popover.left-bottom,
.alpha-i18n-popover.right-bottom {
    top: 35px !important;
    bottom: auto !important;
}
.alpha-i18n-popover.left-top,
.alpha-i18n-popover.right-top {
    top: auto !important;
    bottom: 35px !important;
}
.alpha-i18n-popover.left-top,
.alpha-i18n-popover.left-bottom {
    right: -4px !important;
    left: auto !important;
}
.alpha-i18n-popover.right-top,
.alpha-i18n-popover.right-bottom {
    right: auto !important;
    left: -6px !important;
}
.alpha-i18n-popover.popover.left-bottom > .arrow,
.alpha-i18n-popover.popover.right-bottom > .arrow {
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0,0,0,0.25);
    top: -11px;
}
.alpha-i18n-popover.popover.right-bottom > .arrow {
    left: 5%;
}
.alpha-i18n-popover.popover.left-bottom > .arrow {
    left: 95%;
}
.alpha-i18n-popover.popover.left-bottom > .arrow::after,
.alpha-i18n-popover.popover.right-bottom > .arrow::after {
    content: " ";
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: #fff;
}
.alpha-i18n-popover.popover.left-top > .arrow,
.alpha-i18n-popover.popover.right-top > .arrow {
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: #999;
    border-top-color: rgba(0,0,0,0.25);
    bottom: -11px;
}
.alpha-i18n-popover.popover.right-top > .arrow {
    left: 5%;
}
.alpha-i18n-popover.popover.left-top > .arrow {
    left: 95%;
}
.alpha-i18n-popover.popover.left-top > .arrow::after,
.alpha-i18n-popover.popover.right-top > .arrow::after {
    content: " ";
    bottom: 1px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: #fff;
}
.alpha-i18n-popover > .popover-inner > .popover-content{
    padding: 0;
}
.alpha-i18n-popover .alpha-i18n-heading{
    border-bottom: 1px solid #ccc;
    font-size:14px;
    padding: 5px 15px;
}
.form-holder .alpha-i18n-btn .ventiv-icon{
    font-size: 2em;
    position: relative;
    top: 3px;
    right: 3px
}
.alpha-i18n-btn .ventiv-icon{
    color: #B7B7B7;
    font-size: 2em;
    position: relative;
    top: 3px;
    right: 3px;
}
.alpha-i18n-btn:focus{
    outline: none;
}
.alpha-i18n-btn:hover .ventiv-icon,
.alpha-i18n-btn:focus .ventiv-icon{
    color: #148DCE;
}
.alpha-i18n-popover .alpha-table-cell.invalid-required {
    background-color: #ffeeee !important; /* Overrides other highlights, i.e. for the default language */
}
.alpha-i18n-popover .alpha-table-cell.has-input {
    padding: 0;
}
.alpha-i18n-popover .alpha-i18n-description-input {
    border: 1px solid transparent !important; /* Overrides required styles inherited from the container */
    box-shadow: none;
    height: 100%;
    padding: 4px;
    border-radius: 0;
    font-size:12px;
}
.alpha-i18n-popover .alpha-i18n-description-input:focus {
    border-color: transparent !important;
}
.alpha-i18n-popover .alpha-table-cell.invalid-required .alpha-i18n-description-input {
    border-color: #a94442 !important;
}
.alpha-i18n-table-container{
    margin-bottom: 5px;
}
.alpha-i18n-footer{
    margin-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}
.alpha-i18n-table-container .alpha-table{
    overflow: auto;
}
.alpha-i18n-popover .alpha-table-row .alpha-table-cell div:first-child{
   height: 100%;
}
.mega-menu{
    position: absolute;
    z-index: 1001;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.mega-menu-container{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: var(--roboto);
}
.mega-menu .content{
    position:relative;
    top: -100%;
    width: 100%;
    padding-bottom: 15px;
    color: #FFF;
    padding-top: 35px;
    -webkit-box-shadow: 0 10px 10px 0 #ccc;
    -moz-box-shadow: 0 10px 10px 0 #ccc;
    box-shadow: 0 10px 10px 0 #ccc;
}
.mega-menu .content.show-mega-menu{
    animation-duration: .5s;
    animation-name: slidein;
    top: 60px;
}
.show-mega-menu .alpha-main-tabs{
    opacity: 0;
}
@keyframes  slidein {
    from{
        top: -100%;
    }
    to {
        top: 45px;
    }
}
.mega-menu .mega-menu-filter-container{
    position: relative;
    min-width: 400px;
}
.mega-menu-filter-container span{
    position: absolute;
    top: 6px;
    right: 6px;
    color: #ccc;
}
.mega-menu-close{
    color: #FFF;
    font-size: 1em;
    line-height: .3;
    display: inline-block;
    margin: 15px;
    float: right;
    font-weight: bold;
}
.mega-menu-title{
    color: #FFF;
    font-size: 22px;
    margin-bottom: 15px;
}
.mega-menu .record-type-list{
    flex: 1 1 auto;
}
.mega-menu .record-type-item{
    color: #FFF;
    font-size: 12px;
    font-weight:bold;
    padding: 5px 15px 10px;
}
.mega-menu .record-type-item > span{
    cursor: pointer;
}
.mega-menu .alpha-table-row{
    display: flex;
    align-items: stretch;
    height: auto;
    cursor: pointer;
}
.mega-menu .record-type-item:hover{
    color: #c4e1ef;
}
.record-type-tooltip.tooltip{
    max-width: 500px;
    word-wrap: break-word;
}
.mega-menu .alpha-recent-records-grid{
    border: none;
    max-height: 100%;
    overflow: auto;
}
.mega-menu  .alpha-recent-records-grid .alpha-table-cell{
    height: auto;
    white-space: normal;
    text-align: left;
}
.mega-menu .records-container{
    display: flex;
    flex-direction: row;
    max-height: 75vh;
    margin-top: 15px;
}
.mega-menu .record-type-list-container{
    position: relative;
    overflow-y: auto;
    max-height: 70vh;
    min-height: 115px;
    flex: 1 1 115px;
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 10px;
}

.brandImage.show-mega-menu{
    z-index: 98;
}
.alpha-search-tree{
    display: block;
    height: auto;
    padding: 0;
    line-height: 1.42857143;
    border: 1px solid #ccc;
    box-shadow: none;
    border-radius: 4px;
    background-color: #FFF;
    margin: 0;
    background-image: none;
}
.alpha-search-tree .placeholder{
    color: #AAA;
}
.alpha-search-tree-filter {
    height: 30px;
    position: relative;
    border-radius: 4px;
}
.alpha-search-tree-filter.open{
    height:333px;
}
.open .alpha-search-tree-mask{
    border: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    background-color: #fff;
    display: block;
}
.open .tree-dropdown,
.open .tree-container {
    z-index: 9999;
}
.alpha-search-tree-filter .tree-dropdown{
    height: 30px;
    position: relative;
    cursor: pointer;
    background: transparent;
    padding: 0 12px;
    margin-top: 1px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}
.alpha-search-tree-filter .tree-dropdown .caret{
    color: #555;
    position: absolute;
    right : 5px;
    top: 15px;
}
.alpha-search-tree-filter .tree-dropdown .clear-selection{
    color: #aaa;
    position: absolute;
    right: 20px;
    top: 8px;
}
.alpha-search-tree-filter .tree-dropdown .selected-value,
.alpha-search-tree-filter .tree-dropdown .placeholder{
    padding: 5px;
    display: inline-block;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
}
.alpha-search-tree-filter .tree-container{
    position: relative;
    display: none;
    padding: 5px;
    height: calc(100% - 50px)
}
.alpha-search-tree-filter.open .tree-container{
    display: block;
    z-index: 999;
}
.alpha-search-tree-filter .node-list{
    padding-left: 10px;
}
.alpha-search-tree-filter .tree-list-container{
    height: calc(100% - 37px);
    overflow: auto;
    white-space: nowrap;
}
.alpha-search-tree-filter li .glyphicon{
    font-size: 10px;
}
.alpha-search-tree-filter li ul{
    padding-left: 10px;
}
.alpha-search-tree-filter li{
    padding: 2px 2px 2px 20px;
}
.alpha-search-tree-filter .selected{
    color: #000;
    font-weight: bold;
    background-color: #FFF;
}
.alpha-search-tree-filter .matched button{
    color: #428BCA;
    background-color: #EBF7FF;
}
.alpha-search-tree-filter .selected.matched{
    color: #428BCA;
    background-color: #EBF7FF;
    font-weight: bold;
}
.alpha-search-tree-filter .ventiv-icon-search{
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 15px;
    color: #bbb;
}
.sticky-header {
    position: absolute;
    top: 0;
    z-index: 999;
    visibility: hidden;
}
.primary-bgd {
      background-color: #000;
      color: #FFF;
}

.primary-bgd-footer {
    background-color: #000;
    color: #FFF;
}

.primary-bgd-footer a {
    color: #FFF;
}

.primary-bgd-login-pn-title {
    background-color: #298dcb;
    color: #FFF;
}

.primary-bgd-login-body {
    background-color: #298dcb;
    color: #000;
}

.primary-bgd-login-body-mid {
    background-color: #FFF;
    color: #000;
}

.primary-color{
    color: #000;
}
.alpha-logo {
    float: left;
    width: auto;
    height: 45px;
    margin: 5px 10px;
}
.alpha-logo img{
    max-width: 100%;
    max-height: 100%;
    height: auto;
}

.alpha-client-name {
    margin-top: 22px;
    font-size: 12px;
}
.sticky {
    visibility: visible;
}
.alpha-fixed-header-table{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: none;
    height: 100%;
}
.alpha-fixed-header-table > *{
    flex: 0 0 auto;
}
.alpha-fixed-header-table alpha-smart-table-header{
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
.alpha-fixed-header-table .alpha-table-container{
    flex: 0 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.alpha-fixed-header-table.alpha-fixed-body-table .alpha-table-container{
    flex: 1 1 auto;
}
.alpha-fixed-header-table .alpha-table{
    margin-bottom: 0;
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    overflow-x: auto;
}
.alpha-fixed-header-table .alpha-table-head{
    flex: 0 0 auto;
    width: 100%;
    position: static;
    overflow-x: hidden;
    overflow-y: auto;
}
.alpha-fixed-header-table .alpha-table-body{
    flex: 1 1 auto;
    width: 100%;
    position: relative;
    overflow: auto;
}
.alpha-fixed-header-table .alpha-table-body .alpha-table-row:last-of-type{
    border-bottom: 1px solid #ddd;
}
.ventiv-icon.icon-ic_restrict{
    color: #58b957;
}
.ventiv-icon.icon-ic_multiple_doc{
    color: #b7b7b7;
}
.login-modal .row {
    margin-bottom: 0;
}
.login-modal .alert {
    padding: 0;
}
.alpha-main-tab-frame .login-footer{
    display: none;
}
.alpha-table-cell.associated-record{
    color: #006400;
}
.favorites-menu {
    width: max-content;
    overflow-y: auto;
    max-height: 350px;
}
.favorites-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}
.favorites-menu li a{
    white-space: normal;
}
.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.alpha-lookup-tree button[disabled]{
    cursor: not-allowed;
    color: #a1a1a1;
}
.alpha-lookup-tree .disabled-lock{
    vertical-align: baseline;
    color: #a1a1a1;
}
.access-denied.modal-default .modal-header{
    background-color: #eb9b37;
    color: #000;
}
.access-denied.modal-default .modal-title{
    color: #000;
}
/* Alpha lookup tree */
.alpha-lookup-tree {
    background: 0 0;
    box-shadow: none;
    border: none;
    display: block;
    margin: 0;
    padding: 0;
}
.alpha-lookup-tree-searcher {
    margin-bottom: 6px;
}

.alpha-lookup-tree-filter {
    max-width: 100%;
    position: relative;
}

.alpha-lookup-tree-filter .tree-container {
    position: absolute;
    top: auto ;
    height: 280px;
    padding: 6px;
}

.alpha-table-cell:not(:last-child) .tree-container{
    left: 0;
}

.alpha-table-cell:nth-child(n+4) .tree-container{
    left: unset;
    right: 0;
}

.alpha-lookup-tree-filter .tree-container.loading {
    background-image: url('/Alpha/static/custom/common/images/spinner_40px.gif');
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.alpha-lookup-tree-filter .tree-node.loading {
    background-image: url('/Alpha/static/custom/common/images/spinner_20px.gif');
    background-position: bottom 5px left 50%;
    background-repeat: no-repeat;
    padding-bottom: 30px;
}

.alpha-lookup-tree-filter .loading ul {
    display: none;
}

.alpha-lookup-tree-filter .node-list {
    padding-left: 10px;
}

.alpha-lookup-tree-filter .tree-list-container {
    height: calc(100% - 37px);
    overflow: auto;
    white-space: nowrap;
    position: relative;
}

.alpha-lookup-tree-filter .tree-list-container::-webkit-scrollbar-thumb {
    background-color: #148DCE;
}

.alpha-lookup-tree-filter li .glyphicon {
    font-size: 10px;
}

.alpha-lookup-tree-filter li ul {
    padding-left: 10px;
    position: relative;
}

.alpha-lookup-tree-filter li {
    padding: 2px 2px 2px 14px;
    position: relative;
    line-height: 1.5;
}

.alpha-lookup-tree-filter .selected {
    color: #148DCE !important;
    font-weight: bold;
    background-color: #FFF;
}

.alpha-lookup-tree-filter .tree-search-input {
    padding-right: 50px;
}

.alpha-lookup-tree-filter .tree-search-button {
    position: absolute;
    top: 6px;
    right: 6px;
    height: 34px;
    font-size: 16px;
    line-height: 16px;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.expired-lookup-value{
    color: #a1a1a1;
}
.alpha-lookup-tree .select2-container-multi .select2-search-field .add-more {
    padding-top: 7px;
    width: 20px;
    padding-left: 5px;
    border: none;
    height: 34px;
    line-height: 1.42857;
    display: inline-block;
}
.alpha-lookup-tree.lookup-multiple {
    height: auto;
}
.alpha-lookup-tree .select2-container-multi .select2-search-field .select2-choice {
    min-width: 200px;
    border: none;
}
.alpha-lookup-tree.lookup-multiple .select2-container-multi ul.select2-choices{
    margin-top: 5px;
    margin-bottom: 5px;
}
.alpha-lookup-tree.lookup-multiple .alpha-lookup-tree-filter .tree-container {
    top: 39px;
}
iframe.hyper-link-content{
    border: none;
}

/* Tablets, iPads (landscape) ---------- */
@media screen and (min-width: 1024px) {

    .alpha-logo {
        float: left;
        width: auto;
        height: 45px;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 5px 10px;
    }

    .alpha-client-name {
        margin-top: 19px;
        font-size: 12px;
    }
}

/* pagination */
.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
    background-color: #148DCE;
    border-color: #148DCE;
}

.pagination>li>a, .pagination>li>span {
    color: #148DCE;
}

@media print {

    /*body  {
      padding-top: 72px;
      padding-bottom: 72px ;
    }*/

    body {
        -webkit-print-color-adjust: exact !important;
        page-break-before: avoid;
        /*transform: scale(100);*/
    }

    @page {
        margin-top: 0;
        margin-bottom: 0;
        size: letter;
        max-height:100%;
        max-width:100%;
    }

    a[href]:after {
        content: none !important;
    }

    .view-record-toolbar, .nav-pane, .alpha-main-tabs .alpha-main-tab:first-child,
    .alpha-main-tabs .alpha-main-tab-active, .alpha-main-nav .nav, .ventiv-icon,
    .alpha-global-search-tool, .alpha-main-tabs, .alpha-client-name, .alpha-page-footer {
        display: none;
    }

    .form-group {
        margin-bottom: 10px;
        float: left;
        width: 240px;
    }

    .primary-bgd {
        max-width: 96%;
        position: relative;
        left: 14px;
    }

}
.hover-lookup-tree {
    z-index: 9999;
}
.hover-lookup-tree .tooltip-inner {
    max-width: 355px;
}
.orgTooltip {
    position: relative;
    display: block;
}

.orgTooltip .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 8px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -185%;
    left: 50%;
    margin-left: -60px;
}

.orgTooltip:hover .tooltiptext {
    visibility: visible;
}

.orgTooltip .tooltiptext em{
    display: block;
    font-style:normal;
}

.lookup-tree-tooltip {
    font-family: var(--roboto);
    font-size: 12px;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: left;
}

.lookup-tree-tooltip .tooltiptext em{
    display: block;
    font-style:normal;
}

textarea.rich-text.readonly + div.tox-tinymce > div.tox-editor-container > div.tox-sidebar-wrap > div.tox-edit-area .tox-edit-area__iframe {
    background-color: #eeeeee !important;
    cursor: not-allowed;
}
.thumbnailwithoutborder {
    border: 0;
}
.tooltip-wrap-text .tooltip-inner {
    white-space: pre-wrap;
    word-wrap: break-word;
}
.tooltip.tooltip-wrap-text-pad {
    padding: 5px 0;
    margin-top: -3px;
    margin-left: 30px ;
}
.tooltip-wrap-text-pad .tooltip-inner {
    white-space: pre-wrap;
    word-wrap: break-word;
}
#user_table__header__userStatus__toggle_label {
    color: #A9A9A9;
    pointer-events: none;
}
#user_table__header__userName__toggle_label{
    color: #A9A9A9;
    pointer-events: none;
}
#user_table__header__fullUserName__toggle_label{
    color: #A9A9A9;
    pointer-events: none;
}

.recentrecords-popover {
    max-width: 350px;
    width: 350px;
    position: absolute;
}

.popover-position {
    right:10%;
    left: 65%!important;
}

.recentrecords-popover .popover-content{
    margin:0;
    padding: 3px;
}

.recentrecords-popover-content{
    width: 100%;
    max-width: 100%;
    z-index: 9999;
    font-size: 12px;
    border-radius: 0px;
}

.recentrecords-popover-content div{
    border-radius: 0px;
    margin: 0px;
    padding: 0px;
}

.recentrecords-popover-content .col-key{
    background-color: #ebf7fd;
    margin-left: 0px;
    padding-right: 10px;
    text-align: right;
}

.recentrecords-popover-content .col-val{
    padding-left: 10px;
}

.recentrecords-popover-content .row{
    line-height: 20px;
}

.associaterecords-popover {
    max-width: 550px;
    max-height: 650px;
    position: absolute;
    right:10%;
    left: 17%!important;
    word-wrap: anywhere;
}

.assoc-rec-popover-with-validation-msg {
    left: 32%!important;
}

.associaterecords-popover .arrow{
    display: none;
}

.associaterecords-popover .popover-content{
    margin:0;
    padding: 0;
}

.associaterecords-popover-content{
    width: 100%;
    max-width: 100%;
    z-index: 9999;
    font-size: 12px;
    border-radius: 0px;
    word-wrap: anywhere;
}

.associaterecords-popover-content div{
    border-radius: 0px;
    margin: 0px;
    padding: 0px;
}

.associaterecords-popover-content .col-key{
    background-color: #ebf7fd;
    margin-left: 0px;
    padding-right: 10px;
    text-align: right;
}

.associaterecords-popover-content .col-val{
    padding-left: 10px;
}

.associaterecords-popover-content .row{
    line-height: 20px;
}

.associaterecords-popover-next {
    max-width: 550px;
    max-height: 650px;
    position: absolute;
    right:10%;
    left: 44.5%!important;
    word-wrap: anywhere;
}

.associaterecords-popover-next .arrow{
    display: none;
}

.associaterecords-popover-next .popover-content{
    margin:0;
    padding: 0;
}

.associaterecords-popover-content-next{
    width: 100%;
    max-width: 100%;
    z-index: 9999;
    font-size: 12px;
    border-radius: 0px;
    word-wrap: anywhere;
}

.associaterecords-popover-content-next div{
    border-radius: 0px;
    margin: 0px;
    padding: 0px;
}

.associaterecords-popover-content-next .col-key{
    background-color: #ebf7fd;
    margin-left: 0px;
    padding-right: 10px;
    text-align: right;
}

.associaterecords-popover-content-next .col-val{
    padding-left: 10px;
}

.associaterecords-popover-content-next.row{
    line-height: 20px;
}

.filterApply {
    color: #f37407;
}

.filterDisabled {
    color: #ccc;
}

.alpha-table-heading.expandToggle:not(.editable-grid-header){
    padding-left: 24px;
    padding-right: 24px;
}

.multiline-table-body .multiline-table-row {
    height: auto!important;
}

.multiline-table-body .alpha-table-cell-multiline {
    padding-left: 68px;
    font-size: 12px;
    word-wrap: normal;
}

.alpha-master-detail .alpha-table-body .alpha-table-cell{
    border:none;
}

.alpha-appendable-grid .alpha-table-body .alpha-table-cell{
    border:none;
}

.multiline-row-border-bottom{
    position: absolute;
    width: 100%;
    height: 0px;
    z-index: 100;
}

.alpha-form-list .alpha-table-body:last-child:not(.multiline-table-body) div:last-child .alpha-table-row > .alpha-table-cell {
    border-bottom: none;
}

.alpha-form-list .alpha-table-body:last-child:not(.multiline-table-body) div .alpha-table-row > .alpha-table-cell {
    border-bottom: none;
}

.plusminus{
    font-size:large;
    cursor: pointer;
}

.multiline-white-space{
    white-space: normal;
}

.alpha-master-detail .alpha-table-heading.expandToggle,
.alpha-master-detail .alpha-table-cell.expandToggle
{
    min-width: auto;
}

.multiline-table-body.alpha-table-body .alpha-table-cell{
    height: 29px;
}

.multiline-table-body .multiline-table-row{
    border: none;
    padding-bottom: 10px;
}

.alpha-table-body.multiline-table-body .multiline-table-row {
  padding-bottom: 0 !important;
}

.alpha-table-body.multiline-table-body .multiline-table-row .text-node-body-wrapper {
    display: inline-block;
    width: 95%;
}

.multiline-table-body > .multiline-table-row:last-child{
    border-bottom: none;
}
.multiline-table-row .alpha-table-cell-multiline:nth-last-child(2),
.multiline-table-body .multiline-table-row:last-child .alpha-table-cell-multiline:last-child {
    padding-bottom: 10px;
}

.multiline-table-body .alpha-table-cell-multiline.allLocked{
    padding-top: 29px;
}

.multiline-table-body .multiline-table-row.selected .alpha-table-cell-multiline,
.multiline-table-body .multiline-table-row.selected .alpha-table-cell,
.multiline-table-body .multiline-table-row:hover .alpha-table-cell-multiline
{
    background-color: #ebf7fd!important;
}

.multiline-table-body .multiline-table-row.multiline-row-odd .alpha-table-cell-multiline,
.multiline-table-body .multiline-table-row.multiline-row-odd .alpha-table-cell
{
    background-color: #fafafa;
    border: none;
}

.multiline-table-body .multiline-table-row.multiline-row-even .alpha-table-cell-multiline,
.multiline-table-body .multiline-table-row.multiline-row-even .alpha-table-cell
{
    background-color: #FFFFFF;
    border:none;
}

.alpha-master-detail .alpha-table .alpha-table-body .multiline-row-odd .alpha-table-cell
{
    background-color: #fafafa;
    border: none;
}

.alpha-master-detail .alpha-table .alpha-table-body .multiline-row-even .alpha-table-cell
{
    background-color: #FFFFFF;
    border:none;
}

.alpha-appendable-grid .alpha-table .alpha-table-body .multiline-row-odd .alpha-table-cell
{
    background-color: #fafafa;
    border: none;
}

.alpha-appendable-grid .alpha-table .alpha-table-body .multiline-row-even .alpha-table-cell
{
    background-color: #FFFFFF;
    border:none;
}
.alpha-master-detail .alpha-table .alpha-table-body .multiline-row-odd.selected,
.alpha-master-detail .alpha-table .alpha-table-body .multiline-row-odd.selected .alpha-table-cell,
.alpha-appendable-grid .alpha-table .alpha-table-body .multiline-row-odd.selected,
.alpha-appendable-grid .alpha-table .alpha-table-body .multiline-row-odd.selected .alpha-table-cell,
.alpha-master-detail .alpha-table .alpha-table-body .multiline-row-even.selected,
.alpha-master-detail .alpha-table .alpha-table-body .multiline-row-even.selected .alpha-table-cell,
.alpha-appendable-grid .alpha-table .alpha-table-body .multiline-row-even.selected,
.alpha-appendable-grid .alpha-table .alpha-table-body .multiline-row-even.selected .alpha-table-cell,
.alpha-master-detail .alpha-table .alpha-table-body .multiline-row-odd:hover,
.alpha-master-detail .alpha-table .alpha-table-body .multiline-row-odd:hover .alpha-table-cell,
.alpha-appendable-grid .alpha-table .alpha-table-body .multiline-row-odd:hover,
.alpha-appendable-grid .alpha-table .alpha-table-body .multiline-row-odd:hover .alpha-table-cell,
.alpha-master-detail .alpha-table .alpha-table-body .multiline-row-even:hover,
.alpha-master-detail .alpha-table .alpha-table-body .multiline-row-even:hover .alpha-table-cell,
.alpha-appendable-grid .alpha-table .alpha-table-body .multiline-row-even:hover,
.alpha-appendable-grid .alpha-table .alpha-table-body .multiline-row-even:hover .alpha-table-cell
{
    background-color: #ebf7fd!important;
}

.height25{
    height: 25px;
}

.paddingTop15{
    padding-top: 15px;
}

.modal-noclose .close{
    display: none;
}
.search_table_grid .alpha-table-head, .saved-query-grid .alpha-table-head, .results-grid .alpha-table-head,.user_table_grid .alpha-table-head, .alpha-results-grid .alpha-table-head, .alpha-view-data-loads-grid .alpha-table-head {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
    width: fit-content;
}
.alpha-form-list .alpha-table-head {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 998;
    width: fit-content;
}
.search_table_grid .alpha-table-head, .saved-query-grid .alpha-table-head, .results-grid .alpha-table-head,.user_table_grid .alpha-table-head, .alpha-results-grid .alpha-table-head, .alpha-view-data-loads-grid .alpha-table-head {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
    width: fit-content;
}

.saved-query-grid .alpha-table-body, .results-grid .alpha-table-body {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: fit-content;
}

.work-flow-grid .alpha-table-head, .group_table_grid .alpha-table-head {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
}
.search_table_grid .alpha-table-body, .user_table_grid .alpha-table-body, .group_table_grid .alpha-table-body, .work-flow-grid .alpha-table-body, .alpha-results-grid .alpha-table-body, .alpha-view-data-loads-grid .alpha-table-body {
    min-height:10px;
}
.search_table_grid .alpha-table-body {
    max-height: 60vh;
}
.alpha-results-grid .alpha-table-body {
    max-height: 58vh;
}
.user_table_grid .alpha-table-body {
    max-height:51vh;
}
.group_table_grid .alpha-table-body {
    max-height: 68vh;
}
.work-flow-grid .alpha-table-body {
    max-height: 60vh;
}
.alpha-view-data-loads-grid .alpha-table-body {
    max-height: 82vh;
}
.search_table_grid .alpha-table-row .alpha-table-heading:nth-child(-n + 2), .user_table_grid .alpha-table-row .alpha-table-heading:nth-child(-n + 2), .group_table_grid .alpha-table-row .alpha-table-heading:nth-child(-n + 2), .work-flow-grid .alpha-table-row .alpha-table-heading:nth-child(-n + 4), .alpha-view-data-loads-grid .alpha-table-row .alpha-table-heading:nth-child(1) {
    position: sticky;
    position: -webkit-sticky;
    z-index: 9999;
}

.search_table_grid .alpha-table-row .alpha-table-cell:nth-child(-n + 2), .user_table_grid .alpha-table-row .alpha-table-cell:nth-child(-n + 2), .group_table_grid .alpha-table-row .alpha-table-cell:nth-child(-n + 2), .work-flow-grid .alpha-table-row .alpha-table-cell:nth-child(-n + 4), .alpha-view-data-loads-grid .alpha-table-row .alpha-table-cell:nth-child(1) {
    position: sticky;
    position: -webkit-sticky;
    z-index: 99;
    background: white;
}
.search_table_grid .alpha-table-row .alpha-table-heading:nth-child(1),
.search_table_grid .alpha-table-row .alpha-table-cell:nth-child(1), .user_table_grid .alpha-table-row .alpha-table-heading:nth-child(1),
.user_table_grid .alpha-table-row .alpha-table-cell:nth-child(1), .group_table_grid .alpha-table-row .alpha-table-heading:nth-child(1),
.group_table_grid .alpha-table-row .alpha-table-cell:nth-child(1), .work-flow-grid .alpha-table-row .alpha-table-heading:nth-child(1),
.work-flow-grid .alpha-table-row .alpha-table-cell:nth-child(1), .alpha-view-data-loads-grid .alpha-table-row .alpha-table-heading:nth-child(1),
.alpha-view-data-loads-grid .alpha-table-row .alpha-table-cell:nth-child(1) {
  left: 0;
}
.work-flow-grid .alpha-table-row .alpha-table-heading:nth-child(2),
.work-flow-grid .alpha-table-row .alpha-table-cell:nth-child(2) {
  left: 3%;
}
.work-flow-grid .alpha-table-row .alpha-table-heading:nth-child(3),
.work-flow-grid .alpha-table-row .alpha-table-cell:nth-child(3) {
  left: 6%;
}
.work-flow-grid .alpha-table-row .alpha-table-heading:nth-child(4),
.work-flow-grid .alpha-table-row .alpha-table-cell:nth-child(4) {
  left: 9%;
}
.disabled_label {
    pointer-events: none;
    color:#ccc !important;
}
.search_table_grid .alpha-table-body, .user_table_grid .alpha-table-body, .alpha-results-grid .alpha-table-body, .alpha-view-data-loads-grid .alpha-table-body {
    width:fit-content;
}
.alpha-table-heading.locked, .alpha-table-cell.locked {
    position: sticky;
    position: -webkit-sticky;
}
.multiline-row-odd{
    background-color: #fafafa;
}
.multiline-row-even{
    background-color: #FFFFFF;
}
.claim-alpha-table-body {
    width: fit-content;
}

.alpha-date-time-input-wrapper {
    display: inline-block;
    position: relative;
}
.alpha-date-time-input-wrapper .alpha-date-time-input-calendar-icon {
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -8px;
    color: #337ab7;
    font-size: 15px;
    cursor: pointer;
}

.overview-tab .alpha-date-time-input-calendar-icon {
    right: 20px;
}
.md-view-detail-col-right .layout-grp-header-box.light {
    border-top: 0 !important;
}
.md-view-detail {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
}
.md-view-detail .md-view-detail-col-left {
    flex: 0 0 auto;
    width: 30%;
    overflow: hidden;
}
.md-view-detail-col-left alpha-smart-table-detailed-view {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    overflow: hidden;
}
.md-view-detail-col-left .ast-detail-view {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}
.md-view-detail-col-left .ast-detail-view-pagination {
    padding: 8px;
    background: #EEF4F7;
    font-size: 12px;
    line-height: 16px;
    color: #3B477B;
}
.md-view-detail-col-left .ast-detail-view-pagination .pagination {
    padding: 0;
    margin: 0;
    display: inline-block;
    border-radius: 4px;
}
.md-view-detail-col-left .ast-detail-view-pagination label {
    margin: 0;
}
.md-view-detail-col-left .ast-detail-view-pagination .item-per-page {
    border: 1px solid #D2D2D2;
    border-radius: 4px;
}
.md-view-detail-col-left .ast-detail-view-pagination .pagination > li {
    display: inline;
}
.md-view-detail-col-left .ast-detail-view-pagination .pagination > li > button {
    display: inline-block;
    text-align: center;
    font-size: 12px;
    color: #148dce;
    text-decoration: none;
    background: #fff;
    border: 1px solid #ddd;
    float: none;
    line-height: 28px;
    padding: 0 8px;
    outline: none;
}
.md-view-detail-col-left .ast-detail-view-pagination .pagination > li > button:hover,
.md-view-detail-col-left .ast-detail-view-pagination .pagination > li > button:focus{
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
    text-decoration: none;
    outline: none;
}
.md-view-detail-col-left .ast-detail-view-pagination .pagination > li:first-child > a {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.md-view-detail .md-view-detail-col-right {
flex: 1 0 0;
overflow-x: hidden;
overflow-y: auto;
}
.md-view-detail .md-view-detail-col-right-inner {
padding: 0 12px;
}

.md-view-detail-row {
padding: 8px;
color: #000000;
font-size: 13px;
line-height: 16px;
}
.md-view-detail-row .md-view-details-divider {
margin: 0;
border-top: 1px solid #D0D9E9;
}
.md-view-detail-row .md-view-detail-col:first-child {
font-weight: 700;
}
.md-view-detail-row .btn-view-expand {
position: absolute;
top: 5px;
right: 5px;
padding: 0;
margin: 0;
color: #148DCE;
}
.md-view-detail-row .md-view-detail-row-inner {
padding: 4px;
position: relative;
cursor: pointer;
}

.md-view-detail-row .md-view-detail-row-inner:hover,
.md-view-detail-row .md-view-detail-row-inner.selected {
background: #F8FCFF;
}
.md-view-detail-row .md-view-detail-row-inner.selected {
border-left: 3px solid #71859D;
}

.mode-view-detail .layout-workspace-box,
.mode-view-detail .layout-grp-box,
.mode-view-detail .md-sticky-tlbr,
.mode-view-detail .not-appendable-grid,
.mode-view-detail.content-pane {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.mode-view-detail .alpha-email-popover {
    left: -400px !important;
}
.mode-view-detail  .alpha-email-popover.popover.bottom>.arrow {
    left: 69.5% !important;
}
.md-view-detail-col-left {
    border-right: 1px solid #d0d9e9;
}
.md-view-detail-col-right {
    border-right: 1px solid #d0d9e9;
}
.anchor-disabled {
    background: none !important;
    color: #ccc !important;
    cursor: not-allowed;
}

::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
    background-color: #EEF4F7;
}

::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 20px;
}
.view-detail-text {
    word-break: break-word;
}
.md-view-detail-col-user {
    font-weight: bold;
}
.md-view-detail-col-date {
    font-weight: bold;
    padding-left: 4px;
}
.md-view-detail-col-attachment {
    font-weight: bold;
    color: #148dce;
}

.mode-view-detail .col-md-12 .alpha-email-popover.popover.bottom>.arrow {
    left: 96.5% !important;
}
.mode-view-detail .col-md-12 .alpha-email-popover {
    left: -500px !important;
    min-width: 530px;
    max-width: 530px;
}

.mode-view-detail .alpha-addbtn-popover {
    left: 55px !important;
}
.mode-view-detail .alpha-addbtn-popover.popover.bottom>.arrow {
    left: 7% !important;
}
.alpha-addbtn-selector-footer {
    padding-bottom: 10px;
}
.record-type-selection-dropdown {
    display: inline-block;
    width: 150PX;
    margin-right: 10px;
    vertical-align: middle;
}
.position-relative {
    position: relative;
}

@media screen and (min-device-height: 600px) and (max-device-height:768px) {
    .md-view-detail-col-left .ast-detail-view {
        min-height: 26vh;
    }
    .md-view-detail .md-view-detail-col-right {
        height: 37vh;
    }
}
@media screen and (min-device-height: 768px) and (max-device-height:800px) {
    .md-view-detail-col-left .ast-detail-view {
        min-height: 48vh;
    }
    .md-view-detail .md-view-detail-col-right {
        height: 55vh;
    }
}
@media screen and (min-device-height: 800px) and (max-device-height: 864px) {
    .md-view-detail-col-left .ast-detail-view {
        min-height: 49vh;
    }
    .md-view-detail .md-view-detail-col-right {
        height: 57vh;
    }
}
@media screen and (min-device-height: 864px) and (max-device-height: 900px) {
    .md-view-detail-col-left .ast-detail-view {
        min-height: 54vh;
    }
    .md-view-detail .md-view-detail-col-right {
        height: 60vh;
    }
}
@media screen and (min-device-height: 900px) and (max-device-height: 960px) {
    .md-view-detail-col-left .ast-detail-view {
        min-height: 56vh;
    }
    .md-view-detail .md-view-detail-col-right {
        height: 62vh;
    }
}
@media screen and (min-device-height: 960px) and (max-device-height: 1024px) {
    .md-view-detail-col-left .ast-detail-view {
        min-height: 59vh;
    }
    .md-view-detail .md-view-detail-col-right {
        height: 64vh;
    }
}
@media screen and (min-device-height: 1024px) and (max-device-height: 1050px) {
    .md-view-detail-col-left .ast-detail-view {
        min-height: 61vh;
    }
    .md-view-detail .md-view-detail-col-right{
        height: 66vh;
    }
}
@media screen and (min-device-height: 1050px) {
    .md-view-detail-col-left .ast-detail-view {
        min-height: 63vh;
    }
    .md-view-detail .md-view-detail-col-right{
        height: 67vh;
    }
}
.mode-view-detail::-webkit-scrollbar-thumb {
    background-color: white;
}
.mode-view-detail::-webkit-scrollbar {
    background-color: white;
}
.title-home-icon {
    padding-right: 10px;
    cursor: pointer;
}
.detail-mode button {
    display: inline-flex;
    align-items: center;
}
.detail-mode .ventiv-icon {
    line-height: 12px;
    margin-right: 7px;
    font-size: 17px;
}
.detail-mode .ventiv-icon.icon-ic_cancel_save_on{
    font-size: 18px;
}
.toolbar-record-spacing.toolbar-box button span:first-child {
    padding-right: 3px;
}
.btn-action-master-detail .link-icon {
    width: 22px !important;
    height: 22px !important;
}
.btn-icon-master-detail .unlink-icon {
    width: 22px !important;
    height: 22px !important;
}
.btn-ast-user-filter-options {
    cursor: pointer;
    color: #337ab7;
    font-size: 14px;
    font-weight: 700;
    line-height: 33px;
    padding-right: 2px;
}
.ast-user-filter-operator-selector {
    width: 200px;
    padding: 5px
}
.ast-user-filter-field {
    padding-right: 0px;
    padding-left: 5px;
}

.input-between-field {
    width: calc(100% - 5px);
    float: left;
    position: relative;
    min-height: 1px;
}

.ast-user-filter-field .select2-container .select2-choice,
.ast-user-filter-field .select2-container .select2-choice.select2-default {
    height: 23px;
    line-height: .6;
}
.ast-user-filter-operator-selector .select2-container .select2-choice {
    height: 28px;
    line-height: 1;
}
.ast-user-filter-field .select2-container .select2-choice,
.ast-user-filter-field .select2-container .select2-choice.select2-default,
.ast-user-filter-operator-selector .select2-container .select2-choice {
    box-shadow: none;
    font-size: 12px;
    color: #555 !important;
    font-family: var(--roboto);
    box-shadow: none;
}

.ast-user-filter-field .select2-container .lookup-lib-font{
    font-size: inherit;
}
.ast-user-filter-field .select2-container.form-control {
    margin-top: 5px;
    height: 23px;
}
.ast-user-filter-operator-selector .select2-container.form-control{
    height: 28px;
}

.ast-user-filter-field .select2-container .select2-choice .select2-arrow b {
    background-position: 0 -1px;
}
.ast-user-filter-field-input, .ast-user-filter-field-input .alpha-date-time-input-wrapper {
    margin-top: 5px;
}

.linked-filter-button .link-icon {
    width: 16px;
    height: 16px;
}
.report-section-option .link-icon {
    width: 1.8em;
    height: 1.8em;
}
.link-button .link-icon {
    width: 1.8em;
    height: 1.8em;
}
.record-detail-grid-container .layout-grp-box th:first-child {
    border-left: 1px solid #ccc !important;
}
.record-detail-grid-cell .input-field{
    height: 33px !important;
}
.master-detail-form .input-field-lbl-view {
    padding-right: 0px !important;
    white-space: break-spaces !important;
}
.master-detail-form .field-label-view {
    width: 100%;
    margin-right: 0px;
}
.col-10-field-value {
    padding-left: 10px;
    margin-left: -10px
}
.header-position .height75 .field-container .col-md-12 {
    width: 50%;
}
.header-position .key-field-label-pos, .key-field-value-pos {
    white-space: break-spaces;
}
.content-pane .input-field-lbl-view {
    min-width: 100%;
    max-width: 100%;
}
.field-container-view .col-md-6 {
    padding: 0px;
}
.field-container-view .col-md-2 {
    padding: 0px;
}
.view-mode .col-md-2 {
    padding: 0px;
}
.col-8-field-value {
    padding-left: 10px;
    margin-left: -5px
}
.field-container-view .col-md-4 {
    padding: 0px;
}
.field-container-view .col-md-6 .field-value-view {
    padding-left:10px;
}
.correlated-row {
    width: 100%;
}
.correlated-column {
    width: 50% !important;
}
.correlated-column.field-value-view {
    padding-left: 10px;
    width: 50% !important;
}
.height75 .col-md-4 {
    padding-left: 5px;
}
.height75 .key-field-value-pos {
    padding-left: 10px;
}

.alpha-smart-table-header .query_master_filter_reset {
    font-size: 21px;
    line-height: 1;
}

.ast-user-filter-between-wrapper .between-input-separator {
    padding-top: 12px;
    padding-right: 10px;
    margin-left: 5px;
}

.ast-user-filter-field .alpha-lookup-tree-filter {
    width: calc(100% - 20px);
    display: inline-block;
}

.alpha-table-user-filter-row input.range-control {
    cursor: pointer;
}

.alpha-table-row .select2-search-field .select2-input.select2-default{
    font-size: 12px;
    height: 20px;
}

.alpha-table-row .select2-search-choice{
    font-size: 12px;
}
.ast-user-filter-between-field {
    display: inline-flex;
    width: calc(100% - 10px);
}

.ast-user-filter-multiselect-input[readonly]{
    background-color: white;
}

.ast-user-filter-multiselect-field {
    display: inline-flex;
    width: calc(100% - 20px);
}

.ast-user-filter-between-field .clear-icon, .ast-user-filter-multiselect-field .clear-icon {
    margin-top: 6px;
    margin-left: -15px;
    color: #337ab7;
    font-size: 10px;
}
.ast-user-filter-field-input .alpha-date-time-input-wrapper {
    width: calc(100% - 17px);
    white-space: normal;
}

.ast-user-filter-between-wrapper .alpha-date-time-input-wrapper {
    width: unset;
}

.select2-container .select2-choice abbr {
    top: 60%;
}

.select2-search-choice-close {
    color: #148DCE;
}

.alpha-popover.between-value-popover {
    min-width : fit-content;
}

.alpha-popover.ast-user-filter-popover.ast-user-filter-popover-last.between-value-popover{
    margin-left: -75px;
}

.ast-user-filter-field .tree-search-input.form-control.alpha-lookup-tree-searcher{
    width: 100%;
}

.alpha-smart-table-header .alpha-icon-button-filter {
    border: none;
    background: transparent;
    text-decoration: none;
}

.select2-results li {
    line-height: normal;
}

.multiline-table-body.alpha-table-body .alpha-table-cell{
    height: 37px;
}

.alpha-table-row.editable-grid-row{
    min-height: 34px;
}

.editable-grid-row:last-child {
    margin-bottom: 5px;
}

.alpha-table-body .editable-grid-row .effective_field_left{
    width: 100%;
}

.alpha-table-editable-cell {
    height: 100%;
    overflow: unset;
}


.summarized-field[is-editable-grid="true"] > .input-field {
    color: #000;
    cursor: auto;
}

.alpha-table-editable-cell .alpha-lookup-tree-filter {
    width: calc(100% - 20px);
    max-width: unset;
    display: inline-block;
}

.alpha-table-editable-cell .lookuplibrary-box{
    max-width: unset;
}

.alpha-table-editable-cell .alpha-date-time-input-wrapper{
    width: 100%;
}

.editable-grid-save-confirmation-msg {
    line-height: 2.5;
}

.editable-grid-save-confirmation-msg span {
    font-size: 13px;
    font-weight: bold;
    font-family: var(--roboto);
}

.editable-grid-save-confirmation-msg  .glyphicon-info-sign {
    color: #c00e25;
    font-family: "Glyphicons Halflings"
}

.alpha-table-editable-cell  .glyphicon-info-sign {
    color: #c00e25;
    margin: 6px;
    text-align: center;
    font-family: "Glyphicons Halflings";
}

.editable-grid-save-confirmation-msg  .failed-editable-grid-records {
    color: #c00e25;
}

.editable-grid-save-confirmation-msg .updated-editable-grid-records {
    color: #28b96b;
}

.alpha-table-cell.alpha-table-editable-cell.locked:focus-within{
    z-index: 2;
}

.alpha-table-cell.alpha-table-editable-cell.locked{
    min-height: 37px;
}


.alpha-table-heading.locked.editable-grid-header:first-child, .alpha-table-heading.locked.editable-grid-header:nth-child(2){
    min-width: 25px;
    width: 25px;
}

alpha-table-cell.alpha-table-editable-cell.locked:first-child, alpha-table-cell.alpha-table-editable-cell.locked:nth-child(2) {
    min-width: 25px;
    width: 25px;
}

.editable-grid-error{
    white-space: pre-line;
    line-height: 1.5em;
    padding: 5px 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-bottom: 0;
}

.editable-grid-error-title {
    padding: 10px 12px;
    line-height: 12px;
    font-weight: 500;
    color: #696c74;
    font-size: 14px;
}

.editable-grid-header span.plusminus.glyphicon-plus{
    padding-left: 5px;
}

.glyphicon-color {
    color: #148DCE;
}

.parent-look-up {
    font-weight: bold;
}

.glyphicon-triangle-style {
    position: absolute;
    top: 42%;;
    right: 6px;
    color: #148DCE;
    font-size: smaller;
}

.glyphicon-remove-style {
    position: absolute;
    color: #148dce;
    top: 42%;
    right: 20px;
    font-size: smaller;
}

.tree-container.tree-container-position {
    position: sticky;
}


.alpha-table-editable-cell alpha-date-time-input .alpha-date-time-input-wrapper{
    white-space: normal;
}

.pagination.disabled-pagination a[disabled="disabled"] {
    pointer-events: none;
}

.pagination.disabled-pagination{
    cursor: not-allowed;
}

.editable-grid-readonly{
    padding-left: 20px;
}

.select2-container .select2-choice,
.select2-container .select2-choices,
.select2-container .select2-choices .select2-search-field input{
    font-family: var(--roboto) ;
}

button, input, select,label,form, span,textarea,.btn-sm,.select2-result-label{
    font-family: var(--roboto) !important;
}

.modal,.alpha-modal-button,.btn-group > button{
    font-family: var(--roboto);
}

.glyphicon{
    font-family: "Glyphicons Halflings" !important;
}
.flicker{
    pointer-events: none;
}
.disabled-link {
    pointer-events: none;
    color: grey !important;
}

.correlated-padding{
    padding: 0px 15px;
}

.blueColor{
    color: #0076b9 ! important;
}

.blue-background-color{
    background-color: #0076b9 ! important;
    cursor: pointer;
}

.grayColor{
    color: grey ! important;
}

.margin-top--10{
    margin-top: -10px;
}

.margin-top-10{
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

.large-margin-left {
    margin-left: 450px;
}

.checkbox-margin {
    padding-left: 4px !important;
    padding-right: 57px !important;
}

.glyphicon-pencil{
    cursor: pointer;
    color:#337ab7;
}

.icon-ic_save_blue{
    cursor: pointer;
    color:#337ab7;
}

.view-image-margin-22{
    padding-right: 22px !important;
}

.view-image-margin-16{
    padding-right: 16px !important;
}

.view-image-margin-15{
    padding-right: 15px !important;
}

.view-record-toolbar .glyphicon-paperclip{
    rotate: 180deg;
}

.attachment-tooltip-top {
    margin-top: -20px !important;
}

.attachment-tooltip-top .tooltip-arrow {
    left: 94px !important;
}

.line-height{
    line-height: 20px;
}

.display-flex-space-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}