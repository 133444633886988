/* Bootstrap 3 Overrides */
html {
    font-size: inherit;
}
body {
    font-family: 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    background-color: inherit;
}
input,
button,
select,
textarea {
    font-family: 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: inherit;
}
a {
    color: inherit;
    text-decoration: none;
}

label {
    font-weight: normal !important;
    font-size: 13px;
}

.form-control {
    font-size: 12px;
}

.select2-results .select2-result-label {
    font-size: 12px;
}

.input-field-lbl {
    font-size:13px;
}

h2, .h2 {
    font-size: 24px;
}