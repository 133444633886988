/* Angular General */
[ng-cloak] {
    display: none;
}

html {
    -webkit-font-smoothing: antialiased;
}

/* Firefox Keyframe Animation */
@-moz-keyframes pulse{
    0%{		box-shadow:0 0 1px #008aff;}
    50%{	box-shadow:0 0 8px #008aff;}
    100%{	box-shadow:0 0 1px #008aff;}
}

/* Webkit keyframe animation */
@-webkit-keyframes pulse{
    0%{		box-shadow:0 0 1px #008aff;}
    50%{	box-shadow:0 0 10px #008aff;}
    100%{	box-shadow:0 0 1px #008aff;}
}

form {
    margin: 0;
    font-size: 12px;
}

#contents {
    margin-top: 50px;
}

.icon-calendar {
    background-image: url('/Alpha/static/custom/common/images/calendar.png');
    height: 23px;
    width: 23px;
    margin-top: -2px;
    background-position: 0 0;
}
.user-name-label {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 15px;
}
.view-attachment-body {
    width: 967px;
    max-width: 967px;
    height: auto;
}
.view-attachment-modal {
    top : -25px
}
.view-attachment-dialog {
    width: 1000px;
    height: 90%;
    display: flex;
}
.view-attachment-dialog .modal-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.view-attachment-dialog .modal-content .modal-body {
    flex: 1;
    display: flex;
    overflow: hidden;
}
.view-attachment-text {
    text-decoration: underline;
    cursor:pointer;
    color: #148DCE;
    font-weight: 700;
    font-size: 13px;
    font-family: 'Segoe UI Light','Segoe UI',HelveticaNeue-Light,"Helvetica Neue Light","Helvetica Neue";
}
.view-attachment-preview-icon {
    cursor: pointer;
    margin-right: 4px;
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: sub;
    color:#337ab7;
}
.fill-icon{
    cursor: pointer;
    margin-right: 4px;
    display: inline-block;
    width: 15px;
    height: 15px;
}

.table-icon-dia{
    cursor: pointer;
    margin-right: 4px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: none;
}
.view-attachment-preview-icon-grey {
    background: transparent url(/Alpha/static/custom/common/images/preview-clip_grey.png) center/100%;
}
.view-attachment-preview-icon-blue {
    background: transparent url(/Alpha/static/custom/common/images/preview-clip_blue_1.png) center/100%;
}
.people-fill-icon {
    background: transparent url(/Alpha/static/custom/common/images/people-fill.svg) center/100%;
}
.diagram-3-fill-icon {
    background: transparent url(/Alpha/static/custom/common/images/diagram-3-fill.svg) center/100%;
}
.table-icon {
    background: transparent url(/Alpha/static/custom/common/images/table.svg) center/100%;
}
.list-nested-icon {
    background: transparent url(/Alpha/static/custom/common/images/list-nested.svg) center/100%;
}
.file-content-container-box-view .view-attachment-preview-icon {
    margin-right: 1px;
}
.alpha-rich-text {
    min-height: 150px;
    max-height: 151px;
    overflow-x: hidden;
    overflow-y: auto;
}
.alpha-rich-text-read-only {
    background-color: #eee !important;
    border: 1px solid #ccc;
    opacity: 1;
    cursor: not-allowed;
}
.alpha-table-header-dackpicker-wrapper {
    position: relative;
    margin-right: 5px;
    display: initial;
}
.alpha-table-header-dackpicker-wrapper .form-control {
    height: 30px;
    border-radius: 4px;
    border-color: #66afe9;
    color: #555555;
}
.alpha-table-header-dackpicker-wrapper .alpha-date-time-input-wrapper {
    white-space: normal;
}
.link-icon-blue {
    background: transparent url(/Alpha/static/custom/common/images/link-icon-blue.png) center/100%;
}
.unlink-icon-blue {
    background: transparent url(/Alpha/static/custom/common/images/unlink-icon-blue.png) center/100%;
}
.link-icon-white {
    background: transparent url(/Alpha/static/custom/common/images/link-icon-white.png) center/100%;
}
.unlink-icon-white {
    background: transparent url(/Alpha/static/custom/common/images/unlink-icon-white.png) center/100%;
}
.link-icon {
    cursor: pointer;
    margin-right: 4px;
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: sub;
    color:#337ab7;
}
.unlink-icon {
    cursor: pointer;
    margin-right: 4px;
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: sub;
    color:#337ab7;
}
.padding-5{
    padding: 5px;
}

.line-height-21{
    line-height: 21px !important;
}
