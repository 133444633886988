/**Used by UI Layout in View Compiler and Layout Designer**/
.ui-layout-pane { /* all 'panes' */
    height:	820;
}

.ui-layout-toggler {
    /*border: 1px solid #000000; /!* match pane-border *!/*/
    /*background-color: #000000;*/
    background-color: #808080;
}

.ui-layout-resizer {
    /* all 'resizer-bars' */
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-width: 0;
    background-color: #FFFFFF;
    border-bottom-color: #EFEFEF;
    border-bottom-style: solid;
    border-bottom-width: 0;
    border-left-color: #EFEFEF;
    border-left-color-ltr-source: physical;
    border-left-color-rtl-source: physical;
    border-left-color-value: #EFEFEF;
    border-left-style-ltr-source: physical;
    border-left-width: 1px;
    border-left-width-value: 1px;
    border-right-color: #EFEFEF;
    border-right-color-ltr-source: physical;
    border-right-color-rtl-source: physical;
    border-right-color-value: #EFEFEF;
    border-right-style-value: solid;
    border-right-width: 1px;
    border-right-width-rtl-source: physical;
    border-right-width-value: 1px;
    border-top-color: #EFEFEF;
    border-top-style: solid;
    border-top-width: 0;
    width: 10px;
}

.ui-layout-west-overflow {
    overflow-y: auto;
    overflow-x: hidden
}

/* Resizer (JQuery Layout) */
.ui-layout-toggler {
    /*background-color: #000000;*/
    background-color: #808080;
}

.layout-panel-box {
    padding: 0 0 1.5px 0;
}

.layout-panel-bgd {
    background-color: #fff;
    padding: 20px;
}

.layout-container {
    background-color: #eee;
    padding: 10px;
}

.form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}