/* **************** GRIDS ***************** */

.line, .lastUnit {
    overflow: hidden;
}

.unit {
    float: left;
}

.unitExt {
    float: right;
}
.size1of1 {
    float: none;
    width: 100%;
}

.size1of2 {
    width: 50%;
}

.size2of2 {
    float: none;
    width: 100%;
}

.size1of3 {
    width: 33.33333%;
}

.size2of3 {
    width: 66.66666%;
}

.size3of3 {
    float: none;
    width: 100%;
}

.size1of4 {
    width: 25%;
}

.size2of4 {
    width: 50%;
}

.size3of4 {
    width: 75%;
}

.size4of4 {
    float: none;
    width: 100%;
}

.size1of5 {
    width: 20%;
}

.size2of5 {
    width: 40%;
}

.size3of5 {
    width: 60%;
}

.size4of5 {
    width: 80%;
}

.size5of5 {
    float: none;
    width: 100%;
}

.size1of6 {
    width: 16.66665%;
}

.size2of6 {
    width: 33.33333%;
}

.size3of6 {
    width: 50%;
}

.size4of6 {
    width: 66.66666%;
}

.size5of6 {
    width: 83.333325%;
}

.size6of6 {
    float: none;
    width: 100%;
}

.size1of8 {
    width: 12.5%;
}

.size3of8 {
    width: 37.5%;
}

.size5of8 {
    width: 62.5%;
}

.lastUnit {
    float: none;
    width: auto;
}

/* **************** GRIDS UPDATE ***************** */
.gridUnit {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    float: left;
    position: relative;
    margin-left: 1%;
    margin-right: 1%;
}

.size1of1Grid {
    float: none;
    width: 98%;
}

.size1of2Grid {
    width: 48%;
}

.size2of2Grid {
    float: none;
    width: 98%;
}

.size1of3Grid {
    width: 31.33333%;
}

.size2of3Grid {
    width: 64.66666%;
}

.size3of3Grid {
    float: none;
    width: 98%;
}

.size1of4Grid {
    width: 23%;
}

.size2of4Grid {
    width: 48%;
}

.size3of4Grid {
    width: 73%;
}

.size4of4Grid {
    float: none;
    width: 98%;
}

.size1of5Grid {
    width: 18%;
}

.size2of5Grid {
    width: 38%;
}

.size3of5Grid {
    width: 58%;
}

.size4of5Grid {
    width: 78%;
}

.size5of5Grid {
    float: none;
    width: 98%;
}

.size1of6Grid {
    width: 14.66665%;
}

.size2of6Grid {
    width: 31.33333%;
}

.size3of6Grid {
    width: 48%;
}

.size4of6Grid {
    width: 64.66666%;
}

.size5of6Grid {
    width: 81.333325%;
}

.size6of6Grid {
    float: none;
    width: 98%;
}

.size1of8Grid {
    width: 10.5%;
}

.size3of8Grid {
    width: 35.5%;
}

.size5of8Grid {
    width: 60.5%;
}

/* extending grids to allow a unit that takes the width of its content */
.media {
    width: auto;
}

.gmail {
    width: 160px;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.relative-position {
    position: relative;
}


